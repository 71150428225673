import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  setCurrentChat,
  setCurrentChatId,
  updateChatMessages,
  setCurrentChatProcessing,
  setLoadingDatasetFiles,
  setChatQuery,
  updatingFeedback,
  setProcessingQuery,
  updateProposal,
  setCurrentProposal,
  setCurrentSelectedDataSources,
  setCurrentSelectedDatasetFiles,
  setFocusChatConfigurations,
  setLoadingChatConfigurationsStatus,
  setGenerateResponseToggle,
} from "@app/app/actions";
import { useAppDispatchContext, useAppContext } from "@app/app/appContext";
import {
  chatRequestPayloadType,
  responsesRequestPayload,
  generateResponsesRequestPayload,
  requirementExcludePayload,
  requirementFeedbackPayload,
} from "@app/api/types";
import * as CHAT_TYPES from "@app/api/chats/types";

import { apiConfig } from "@app/api/index";

import * as CHAT_API from "@app/api/chats/endpoints";
import * as RFP_API from "@app/api/rfp/endpoints";
import { CHAT_MODE, DatasetType } from "../types";
import { useState } from "react";
import { unloadApplication } from "single-spa";

export type continueChatRequestType = {
  id: string;
  prompt: string;
  mode?: string;
  skill?: string;
  datasets?: {
    dataset_id: string;
    files: string[];
  }[];
  focus?: string;
};
export const useSendMessage = (id: string) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();
  const { currentChatMode } = useAppContext();

  return useMutation({
    mutationFn: (props: continueChatRequestType) =>
      apiConfig[CHAT_API.ENDPOINT_CONTINUE_CHAT](props),
    onMutate: (props) => {
      dispatch(
        updateChatMessages({
          timestamp_id: id,
          processingMessage: props.prompt,
          isProcessing: true,
        })
      );
    },
    onSuccess: (messages) => {
      if (messages.length > 0) {
        dispatch(
          updateChatMessages({
            timestamp_id: id,
            messages,
            isProcessing: false,
          })
        );
        queryClient.invalidateQueries({
          queryKey: ["chats", id],
        });
      } else {
        dispatch(
          updateChatMessages({
            timestamp_id: id,
            error: "Canceled request",
            isProcessing: false,
          })
        );
      }
    },
    onError: (error) => {
      dispatch(
        updateChatMessages({
          timestamp_id: id,
          error: "An unexpected error has occurred.",
          isProcessing: false,
        })
      );
    },
  });
};

export const useInitialSendMessage = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();
  const navigate = useNavigate();
  const { isFocusedChat } = useAppContext();

  return useMutation({
    mutationFn: (props: continueChatRequestType) =>
      apiConfig[CHAT_API.ENDPOINT_INITIALIZE_CHAT](props),
    onMutate: (props) => {
      console.log("ddd ENDPOINT_INITIALIZE_CHAT onMutate", props);
      dispatch(setChatQuery({ query: null }));
      dispatch(setProcessingQuery({ query: props.prompt }));
      dispatch(setCurrentChatProcessing(true));
      dispatch(setCurrentSelectedDataSources({ focus: props.focus }));
    },
    onSuccess: (props) => {
      if (props.length === 0) {
        dispatch(setCurrentChat({ id: null, error: "Canceled request" }));
        dispatch(setCurrentChatProcessing(false));
      } else if (props.thread.length > 0) {
        navigate(`/kchat/chat/${props.timestamp_id}?support-focus-chat=true`);
        dispatch(setCurrentChatProcessing(false));
        dispatch(setCurrentChat({ id: props.timestamp_id, error: null }));
        console.log("ddd ENDPOINT_INITIALIZE_CHAT", props);
        queryClient.invalidateQueries({ queryKey: ["chatList"] });
        queryClient.invalidateQueries({
          queryKey: ["chats", props.timestamp_id],
        });
      }
    },
    onError: (error) => {
      dispatch(
        setCurrentChat({ id: null, error: "An unexpected error has occurred." })
      );
      dispatch(setCurrentChatProcessing(false));
    },
  });
};

export const useTrackFeedback = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();
  return useMutation({
    mutationFn: ({ chatId, index, feedback }: any) =>
      apiConfig[CHAT_API.ENDPOINT_TRACK_FEEDBACK](chatId, index, feedback),
    onMutate: (props) => {
      dispatch(
        updatingFeedback({
          ...props,
          index: parseInt(props.index),
          feedback: parseInt(props.feedback),
          processing: true,
        })
      );
    },
    onSuccess: (props) => {
      if (props) {
        const keys = Object.keys(props);
        const total = keys.length;
        let chatId = null;
        keys.forEach((key, idx) => {
          const { feedback, index, timestamp_id } = props[key];
          chatId = timestamp_id;
          dispatch(
            updatingFeedback({
              chatId: timestamp_id,
              index: parseInt(index),
              feedback: parseInt(feedback),
              processing: false,
            })
          );
        });

        queryClient.fetchQuery({
          queryKey: ["feedback", chatId],
        });
      }
    },
    onError: (error) => {
      dispatch(setCurrentChat({ id: null, error }));
    },
  });
};

export const useCreateProposal = (id: string) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();
  const { currentProposal } = useAppContext();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (props: any) =>
      apiConfig[RFP_API.ENDPOINT_PROPOSAL_POST](props),
    onMutate: (props) => {
      dispatch(setCurrentProposal({ isProcessing: true, error: null }));
    },
    onSuccess: (data: any) => {
      const nextStatus =
        currentProposal.status === "header-info"
          ? "document"
          : currentProposal.status === "document"
          ? "review"
          : currentProposal.status === "review"
          ? "submit"
          : "header-info";

      dispatch(
        setCurrentProposal({
          id: data.proposal_id,
          isProcessing: false,
          status: nextStatus,
        })
      );
      console.log(data.proposal_id)
      navigate(`/proposal/${data.proposal_id}/${nextStatus}`);
      queryClient.invalidateQueries({
        queryKey: ["proposalList", data.proposal_id],
      });
    },
    onError: (error) => {
      dispatch(setCurrentProposal({ error, isProcessing: false }));
    },
  });
};

export const useUpdateProposal = (id: string) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();
  const { currentProposal } = useAppContext();
  const navigate = useNavigate();
  const [shouldGoNextStatus, setShouldGoNextStatus] = useState(true);

  return useMutation({
    mutationFn: (props: any) =>
      apiConfig[RFP_API.ENDPOINT_PROPOSAL_PUT](id, props),
    onMutate: (props) => {
      if (props.isExcluded !== undefined) {
        setShouldGoNextStatus(false);
      } else {
        setShouldGoNextStatus(true);
      }
      dispatch(setCurrentProposal({ isProcessing: true, error: null }));
    },

    onSuccess: (props) => {
      console.log("ddd put proposal onSuccess", props);
      const nextStatus =
        currentProposal.status === "header-info"
          ? "document"
          : currentProposal.status === "document"
          ? "win-theme-review"
          : currentProposal.status === "win-theme-review"
          ? "requirement-review"
          : currentProposal.status === "requirement-review"
          ? "requirement-review"
          : "header-info";
      dispatch(
        setCurrentProposal({
          id,
          isProcessing: false,
          status: shouldGoNextStatus ? nextStatus : currentProposal.status,
        })
      );
      // navigate(`/proposal/${id}/${nextStatus}`);
      queryClient.invalidateQueries({
        queryKey: ["proposalList", id],
      });
    },
    onError: (error) => {
      dispatch(setCurrentProposal({ error }));
    },
  });
};

export const useUploadTemplate = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();
  const { currentProposal } = useAppContext();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (props: any) =>
      apiConfig[RFP_API.ENDPOINT_TEMPLATE_POST](props),
    onMutate: (props) => {
      dispatch(setCurrentProposal({ isProcessing: true, error: null }));
    },
    onSuccess: async (props) => {
      await queryClient.refetchQueries({
        queryKey: ["templateList"],
      });
      dispatch(
        setCurrentProposal({
          isProcessing: false,
        })
      );
      // navigate(`/proposal/${currentProposal.id}/template`);
    },
    onError: (error) => {
      dispatch(setCurrentProposal({ error }));
    },
  });
};

export const useChatProposal = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();
  const { currentProposal } = useAppContext();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (props: {
      proposal_id: string;
      requirement_id: string;
      query: string;
    }) =>
      apiConfig[RFP_API.ENDPOINT_CHAT_PROPOSAL_POST]({
        proposal_id: props.proposal_id,
        requirement_id: props.requirement_id,
        query: props.query,
      }),
    onMutate: (props) => {
      dispatch(setCurrentProposal({ isProcessing: true, error: null }));
    },
    onSuccess: (props) => {},
    onError: (error) => {
      dispatch(setCurrentProposal({ error }));
    },
  });
};
export const useChatRequest = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();
  const { currentProposal } = useAppContext();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (props: chatRequestPayloadType) =>
      apiConfig[RFP_API.ENDPOINT_CHAT_PROPOSAL_REQUEST_POST](props),
    onMutate: (props) => {
      dispatch(setCurrentProposal({ isProcessing: true, error: null }));
    },
    onSuccess: (props) => {},
    onError: (error) => {
      dispatch(setCurrentProposal({ error }));
    },
  });
};

export const useChatWinTheme = (id: string) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();
  return useMutation({
    mutationFn: (props: any) =>
      apiConfig[RFP_API.ENDPOINT_CHAT_WINTHEME_POST](props),
    onMutate: (props) => {
      dispatch(setCurrentProposal({ error: null, isSendingWinThemeMessage: true }));
    },
    onSuccess: (data, props) => {
      dispatch(
        setCurrentProposal({
          error: null,
          isSendingWinThemeMessage: false,
          winTheme: data.summary,
          winThemeMessages: data.messages,
        })
      );
      queryClient.refetchQueries({
        queryKey: ["proposals", id],
      });
    },
    onError: (error) => {
      dispatch(setCurrentProposal({ error, isSendingWinThemeMessage: false }));
    },
  });
};

export const useChatRequirement = (id: string) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();
  return useMutation({
    mutationFn: (props: chatRequestPayloadType) =>
      apiConfig[RFP_API.ENDPOINT_CHAT_REQUIREMENT_POST](props),
    onMutate: (props) => {
      dispatch(
        setCurrentProposal({ error: null, isSendingRequirementMessage: true })
      );
    },
    onSuccess: (props) => {
      dispatch(
        setCurrentProposal({
          error: null,
          isSendingRequirementMessage: false,
        })
      );
      queryClient.refetchQueries({
        queryKey: ["proposals", id],
      });
    },
    onError: (error) => {
      dispatch(setCurrentProposal({ error, isSendingRequirementMessage: false }));
    },
  });
};
export const useChatResponse = (id: string) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();
  return useMutation({
    mutationFn: (props: chatRequestPayloadType) =>
      apiConfig[RFP_API.ENDPOINT_CHAT_RESPONSE_POST](props),
    onMutate: (props) => {
      dispatch(
        setCurrentProposal({
          error: null,
          isSendingResponseMessage: true,
        })
      );
    },
    onSuccess: (props) => {
      dispatch(
        setCurrentProposal({
          error: null,
          isSendingResponseMessage: false,
        })
      );

      queryClient.refetchQueries({
        queryKey: ["proposals", id],
      });
    },
    onError: (error) => {
      dispatch(setCurrentProposal({ error, isSendingResponseMessage: false }));
    },
  });
};

export const useResponses = () => {
  const dispatch = useAppDispatchContext();
  return useMutation({
    mutationFn: (props: responsesRequestPayload) =>
      apiConfig[RFP_API.ENDPOINT_RESPONSE_GET](props),
    onMutate: (props) => {
      console.log("ddd response", props);
      dispatch(setCurrentProposal({ isProcessing: true, error: null }));
    },
    onSuccess: (props) => {
      console.log("ddd onSuccess", props);
    },
    onError: (error) => {
      dispatch(setCurrentProposal({ error }));
    },
  });
};

export const useGenerateResponses = () => {
  const dispatch = useAppDispatchContext();
  return useMutation({
    mutationFn: (props: generateResponsesRequestPayload) => {
      console.log(props);
      dispatch(setGenerateResponseToggle(
        { 
          [props.derived_from_id]: { 
            isGenerating: true, 
            response: { summary: "Generating response...", citations: null } 
          }
        }
      ));
      return apiConfig[RFP_API.ENDPOINT_RESPONSE_POST](props);      
    },

    onMutate: (props) => {
      console.log("ddd response", props);
      dispatch(setCurrentProposal({ isProcessing: true, error: null }));      
    },
    onSuccess: (data, props) => {
      console.log("ddd onSuccess", props);
      dispatch(setGenerateResponseToggle({ [data['derivedFromId']]: { isGenerating: false, response: data['response'] } }))
    },
    onError: (error, props) => {
      dispatch(setGenerateResponseToggle({ [props.derived_from_id]: { isGenerating: false, response: { summary: error.toString() , citations: [] } }}))
      dispatch(setCurrentProposal({ error }));
    },
  });
};

export const useFinalizeProposal = () => {
  const dispatch = useAppDispatchContext();
  return useMutation({
    mutationFn: (props: responsesRequestPayload) =>
      apiConfig[RFP_API.ENDPOINT_FINALIZE_GET](props),
    onMutate: (props) => {
      dispatch(setCurrentProposal({ isFinalizeProcessing: true, error: null }));
    },
    onSuccess: (data) => {
      dispatch(setCurrentProposal({ isFinalizeProcessing: false, docxUrl: data['document'] }))
    },
    onError: (error) => {
      dispatch(setCurrentProposal({ error, isFinalizeProcessing: false }));
    },
  });
};

export const useRequirementExclude = (id: string) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();

  return useMutation({
    mutationFn: (props: requirementExcludePayload) =>
      apiConfig[RFP_API.ENDPOINT_REQUIREMENT_EXCLUDE](props),
    onMutate: (props) => {
      dispatch(
        setCurrentProposal({
          error: null,
          isSendingExcluded: true,
        })
      );
    },
    onSuccess: (props) => {
      dispatch(
        setCurrentProposal({
          error: null,
          isSendingExcluded: false,
        })
      );
      queryClient.refetchQueries({
        queryKey: ["proposals", id],
      });
    },
    onError: (error) => {
      dispatch(setCurrentProposal({ error }));
    },
  });
};

export const useRequirementFeedback = (id: string) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();

  return useMutation({
    mutationFn: (props: requirementFeedbackPayload) =>
      apiConfig[RFP_API.ENDPOINT_REQUIREMENT_FEEDBACK](props),
    onMutate: (props) => {
      dispatch(
        setCurrentProposal({
          error: null,
          isSendingFeedback: true,
        })
      );
    },
    onSuccess: (props) => {
      dispatch(
        setCurrentProposal({
          error: null,
          isSendingFeedback: false,
        })
      );
      queryClient.refetchQueries({
        queryKey: ["proposals", id],
      });
    },
    onError: (error) => {
      dispatch(setCurrentProposal({ error }));
    },
  });
};

export const useFileList = (
  url?:
    | typeof CHAT_API.ENDPOINT_LIST_FILES
    | typeof RFP_API.ENDPOINT_PROPOSAL_LIST_FILES
) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatchContext();

  return useMutation({
    mutationFn: (props: CHAT_TYPES.ListFilesRequestType) =>
      //@ts-ignore
      apiConfig[url || CHAT_API.ENDPOINT_LIST_FILES](props),
    onMutate: (props) => {
      dispatch(setLoadingDatasetFiles(true));
    },
    onSuccess: (props) => {
      const { files } = props;
      const datasetId = files[0]?.id;
      if (files && files.length > 0) {
        dispatch(
          setCurrentSelectedDatasetFiles({
            files: props.files,
          })
        );
      }
      dispatch(setLoadingDatasetFiles(false));
    },
    onError: (error) => {
      dispatch(setLoadingDatasetFiles(true));
    },
  });
};

export const useListFocusChat = () => {
  const dispatch = useAppDispatchContext();

  return useMutation({
    mutationFn: (props: CHAT_TYPES.ListChatConfigurationRequestType) =>
      apiConfig[CHAT_API.ENDPOINT_LIST_CHAT_CONFIGURATION](props),
    onMutate: (props) => {
      dispatch(setLoadingChatConfigurationsStatus(true));
    },
    onSuccess: (props) => {
      dispatch(setLoadingChatConfigurationsStatus(false));
      if (props.configurations) {
        dispatch(setFocusChatConfigurations(props.configurations));
      }
    },
    onError: (error) => {
      dispatch(setLoadingChatConfigurationsStatus(false));
    },
  });
};

export const useUpdateChatConfiguration = () => {
  const dispatch = useAppDispatchContext();

  return useMutation({
    mutationFn: (props: CHAT_TYPES.UpdateChatConfigurationRequestType) =>
      apiConfig[CHAT_API.ENDPOINT_UPDATE_CHAT_CONFIGURATION](props),
    onMutate: (props) => {
      dispatch(setLoadingChatConfigurationsStatus(true));
    },
    onSuccess: (props) => {
      dispatch(setLoadingChatConfigurationsStatus(false));
    },
    onError: (error) => {
      dispatch(setLoadingChatConfigurationsStatus(false));
    },
  });
};

export const useStopRequest = () => {
  const dispatch = useAppDispatchContext();

  return useMutation({
    mutationFn: () => apiConfig[CHAT_API.CANCEL_REQUEST](),
    onSuccess: (stopped: boolean) => {},
    onError: (error) => {
      dispatch(
        updateChatMessages({
          timestamp_id: null,
          error,
          isProcessing: false,
        })
      );
    },
  });
};
