import { useEffect, useState } from "react";
import { LoadIndicator } from "devextreme-react/load-indicator";

import { FocusCard, FocusCardContainer } from "@app/components/landing/styles";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faUserLarge,
  faBarsProgress,
  faGrip,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { OverflowEllipsisText, HistoryWrapper } from "./styles";
import moment from "moment-timezone";
import { defaultFormat, userTimeZone } from "@app/utils";
import { queryProposalList } from "@app/app/queries";
import { setCurrentProposal } from "@app/app/actions";
import { useAppContext, useAppDispatchContext } from "@app/app/appContext";
import { useNavigate } from "react-router-dom";
import { EDIT_MODE } from "@app/components/proposal/types";
import { ButtonGroup, ButtonGroupTypes } from "devextreme-react/button-group";

import { DataGrid, Column, SearchPanel } from "devextreme-react/data-grid";
import { PROPOSAL_STATUS } from "@app/components/proposal/types";
import { Button } from "devextreme-react/button";

export const ProposalList = () => {
  const dispatch = useAppDispatchContext();
  const [showList, setShowList] = useState(false);
  const navigate = useNavigate();
  // const { isLoading, error, data } = useQuery({
  //   queryKey: ["chatList"],
  //   queryFn: () => apiConfig[CHAT_API.ENDPOINT_LIST_CHAT](),
  // });

  const { isLoading, error, data, refetch } = queryProposalList();

  useEffect(() => {
    refetch();
  }, []);

  const loadChatMessages = (e: React.MouseEvent<HTMLLIElement>) => {
    // const { timestampId } = e.currentTarget.dataset;
    // dispatch(setCurrentChatId({ id: timestampId }));
    // dispatch(setChatQuery({ query: null }));
    // dispatch(
    //   setCurrentSelectedDataSources({ focus: chat.currentFocusChatConfig })
    // );
    // navigate(`/kchat/chat/${timestampId}?support-focus-chat=true`); // TODO We should set constants for the routes
  };

  const editProposal = (data) => {
    const { id, status } = data;

    dispatch(setCurrentProposal({ id, status }));
    navigate(`/proposal/${id}/${status}`, { state: { mode: EDIT_MODE.EDIT } });
  };
  enum ButtonType {
    Success = "success",
  };
  const alignments = [
    {
      icon: "contentlayout",
      text: "list",
      alignment: "left",
      hint: "Align left",
      type: ButtonType.Success,
    },
    {
      icon: "smalliconslayout",
      text: "tile",
      alignment: "right",
      hint: "Align left",
      type: ButtonType.Success,
    },
  ];

  const itemClick = (e: ButtonGroupTypes.ItemClickEvent) => {
    const type = e.itemData.text;
    setShowList(type == "list");
  };

  const selectedItemKeys = ["right"];

  const dataEntryRow = (e) => {
    if (e.rowType == "data" && e.rowIndex % 2 === 0) {
      e.rowElement.style.backgroundColor = "#f5f5f5";
    }
  };

  const newProposal = () => {
    dispatch(setCurrentProposal({ id: null, status: null }));
    navigate("/proposal/edit");
  };


  const proposalActions = (data) => {
    const { status } = data.data;
    if (Object.values(PROPOSAL_STATUS).includes(status)) {
      return <Button icon={"rename"} onClick={() => editProposal(data.data)} />;
    }
  };
  return (
    <>
      <div className="m-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="mr-3 mb-0">RFP History</h5>
          <Button stylingMode="outlined" type="success" onClick={newProposal}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Start New
          </Button>
        </div>
        <ButtonGroup
          items={alignments}
          keyExpr="alignment"
          stylingMode="outlined"
          defaultSelectedItemKeys={selectedItemKeys}
          onItemClick={itemClick}
        />
      </div>
      <HistoryWrapper className="m-3">
        {showList ? (
          <DataGrid
            id="dataGrid"
            dataSource={data}
            keyExpr="id"
            showBorders={true}
            allowColumnReordering={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            onRowPrepared={dataEntryRow}
            height="100%"
          >
            <SearchPanel visible={true} />
            <Column dataField="id" caption="Proposal Id" />
            <Column dataField="proposal_name" caption="Proposal Name" />
            <Column
              dataField="opportunity_number"
              caption="Opportunity Number"
            />
            <Column dataField="proposal_manager" caption="Proposal Manager" />
            <Column dataField="capture_manager" caption="Capture Manager" />
            <Column dataField="status" caption="Status" />
            <Column
              dataField="created"
              caption="Created At"
              calculateDisplayValue={(data) => {
                return (
                  data.created &&
                  moment
                    .unix(parseInt(data.created))
                    .tz(userTimeZone)
                    .format(defaultFormat)
                );
              }}
            />
            <Column
              dataField="updated"
              caption="Updated At"
              calculateDisplayValue={(data) => {
                return (
                  data.created &&
                  moment
                    .unix(parseInt(data.updated))
                    .tz(userTimeZone)
                    .format(defaultFormat)
                );
              }}
            />
            <Column
              caption="Action"
              fixed={true}
              fix-position={"right"}
              allowFixing={true}
              cellRender={proposalActions}
            />
          </DataGrid>
        ) : (
          <FocusCardContainer className="d-flex">
            {isLoading && (
              <FocusCard className={`d-flex align-items-center`}>
                <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                  <LoadIndicator />
                </Card.Body>
              </FocusCard>
            )}
            {!error &&
              !isLoading &&
              Array.isArray(data) &&
              data?.map((i, index) => (
                <FocusCard
                  key={index}
                  data-timestamp-id={i.timestamp_id}
                  className={`d-flex align-items-center`}
                  onClick={() => editProposal(i)}
                >
                  <Card.Body className="d-flex flex-column align-items-start justify-content-between w-100">
                    <div>
                      <OverflowEllipsisText
                        line={3}
                        textAlign={"left"}
                        className={"font-weight-bold"}
                      >
                        {i.proposal_name}
                      </OverflowEllipsisText>
                    </div>
                    <div className="detail w-100">
                      <div>
                        <FontAwesomeIcon icon={faUserTie} />
                        <span className="ml-2">{i.capture_manager}</span>
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faUserLarge} />
                        <span className="ml-2"> {i.proposal_manager}</span>
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faBarsProgress} />
                        <span className="ml-2">{i.status}</span>
                      </div>

                      <div className="timestamp  mt-2">
                        {i.updated &&
                          moment
                            .unix(parseInt(i.updated))
                            .tz(userTimeZone)
                            .format(defaultFormat)}
                      </div>
                    </div>
                  </Card.Body>
                </FocusCard>
              ))}
          </FocusCardContainer>
        )}
      </HistoryWrapper>
    </>
  );
};
