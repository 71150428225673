import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faCircleStop,
} from "@fortawesome/free-regular-svg-icons";
import { useAppContext } from "@app/app/appContext";
import { InputWrapper, BottomArea } from "@app/components/chat-input/styles";
import {
  useSendMessage,
  useInitialSendMessage,
  useUpdateChatConfiguration,
  useStopRequest,
} from "@app/app/mutations";
import { PlainButton } from "@app/styles";
import { setChat } from "@app/app/actions";
import { CHAT_MODE } from "@app/app/types";

export const ChatInput = () => {
  const [value, setValue] = useState("");
  const chat = useAppContext();
  const { hideLeftPanel } = chat;
  const { currentChat, chats, currentChatMode, currentSelectedSource } = chat;
  const { skill, mode, datasets, files, focus } = currentSelectedSource;
  const [message, setMessage] = useState(null);
  const quillRef = React.createRef();
  const { id } = currentChat || {};
  const initialSendMessage = useInitialSendMessage();
  const sendMessage = useSendMessage(id);
  const stopRequest = useStopRequest();
  const updateChatConfiguration = useUpdateChatConfiguration();

  const { auth } = useAppContext();
  const modules = {
    toolbar: false,
  };
  const isChatProcessing = id
    ? chats.find((c) => c.timestamp_id.toString() == id.toString())
        ?.isProcessing
    : initialSendMessage.isLoading;

  const selectionChange = (e: any) => {};

  useEffect(() => {
    setChat({ timestamp_id: id, isProcessing: initialSendMessage.isLoading });
  }, [initialSendMessage.isLoading]);

  //set button click text
  useEffect(() => {
    setValue(chat.currentChatQuery);
  }, [chat.currentChatQuery]);

  //set focust to end
  useEffect(() => {
    if (chat.currentChatQuery == value) {
      //@ts-ignore
      const quill = quillRef.current.getEditor();
      quill.focus();
      quill.setSelection(quill.getLength(), 0);
    }
  }, [value]);

  const onClickStopButton = () => {
    stopRequest.mutate();
  };
  const onSendMessage = () => {
    //@ts-ignore
    const prompt = quillRef.current
      //@ts-ignore
      .getEditor()
    .root.innerHTML;

    //send message
    const filesByDataset = {};
    files?.forEach((f) => {
      if (!filesByDataset[f.datasource]) {
        filesByDataset[f.datasource] = [];
      }
      filesByDataset[f.datasource].push(f.id);
    });
    const datasetSelectedFiles =
      currentSelectedSource.datasets?.map((dataset) => {
        return {
          dataset_id: dataset.id,
          files: filesByDataset[dataset.id],
        };
      }) || [];

    const currentFocus = chat.currentChat.id
      ? focus?.toLowerCase()
      : (chat.currentFocusChatConfig || "")?.toLowerCase();
    const payload = {
      id,
      prompt,
      skill,
      mode: mode || CHAT_MODE.CHAT_GPT3,
      datasets: datasetSelectedFiles,
      focus: currentFocus,
    };
    if (id) {
      // if(currentFocus) {
      //   updateChatConfiguration.mutate({
      //     focus_chat_id: focus,
      //     configuration: {
      //       prompt,
      //       datasets: datasetSelectedFiles.map(d=>d.dataset_id)
      //     }
      //   })
      // }
      sendMessage.mutate(payload);
    } else {
      initialSendMessage.mutate(payload);
    }
    setMessage(prompt);
    setValue("");
  };
  const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    }
    if (e.key === "Enter" && !isChatProcessing) {
      e.preventDefault();
      onSendMessage();
    }
  };
  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    onSendMessage();
  };
  
  return (
    <BottomArea className="d-flex align-items-center">
      <InputWrapper className="d-flex align-items-center" hide={hideLeftPanel}>
        <ReactQuill
          //@ts-ignore
          ref={quillRef}
          modules={modules}
          formats={[]}
          value={value}
          theme="snow"
          onChange={setValue}
          onKeyDown={onKeydown}
          onChangeSelection={selectionChange}
          placeholder="Send a message..."
          className="inputbox flex-grow-1"
        />
        <PlainButton
          disabled={!value || value.trim() === "<p><br></p>"}
          hidden={isChatProcessing}
          onClick={onClick}
        >
          <FontAwesomeIcon icon={faPaperPlane} className={`cursor-pointer`} />
        </PlainButton>
        {isChatProcessing && (
          <PlainButton onClick={onClickStopButton}>
            <FontAwesomeIcon icon={faCircleStop} className={`cursor-pointer`} />
          </PlainButton>
        )}
      </InputWrapper>
    </BottomArea>
  );
};
