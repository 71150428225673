import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { PROPOSAL_STATUS } from "@app/components/proposal/types";
import {
  FixedContentWrapper,
  ProposalContentWraper,
} from "@app/components/proposal/styles";
import { useAppContext, useAppDispatchContext } from "@app/app/appContext";
import TopNavigation from "@app/components/proposal/top-navigation";
import { setCurrentProposal } from "@app/app/actions";
import { v4 as uuidv4 } from "uuid";
import ProposalHeaderInfo from "@app/components/proposal/header-info";
import ProposalDocument from "@app/components/proposal/document";
import ProposalTemplate from "@app/components/proposal/template";
import ProposalRequirementReview from "@app/components/proposal/requirement-review";
import WinThemeReview from "@app/components/proposal/win-theme-review";
import { EDIT_MODE } from "@app/components/proposal/types"

const Proposal = () => {
  const state = useAppContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatchContext();
  const {
    currentProposal: { id: currentProposalId, status: currentProposalStatus },
  } = state;
  const { state: locaionState } = useLocation();
  const { mode } = locaionState || {};
  const { status, proposalId } = useParams();

  useEffect(() => {
    const id = proposalId == undefined ? uuidv4() : proposalId;
    const proposalStatus =
      status == undefined ? PROPOSAL_STATUS.HEADER_INFO : status;
    dispatch(setCurrentProposal({ id, status: proposalStatus }));
    if (proposalId == undefined) {
      navigate(`/proposal/${id}/${proposalStatus}`, { state: { mode: EDIT_MODE.NEW} });
    }
  }, []);
  return (
    <FixedContentWrapper className="d-flex bg-white flex-column">
      <TopNavigation />
      <ProposalContentWraper className="d-flex flex-column justify-content-betweend-flex justify-content-between flex-grow-1 h-100 overflow-auto">
        {currentProposalStatus == PROPOSAL_STATUS.HEADER_INFO && (
          <ProposalHeaderInfo />
        )}
        {currentProposalStatus == PROPOSAL_STATUS.DOCUMENT && (
          <ProposalDocument />
        )}
        {currentProposalStatus == PROPOSAL_STATUS.TEMPLATE && (
          <ProposalTemplate />
        )}
        {currentProposalStatus == PROPOSAL_STATUS.REQUIREMENT_REVIEW && (
          <ProposalRequirementReview />
        )}
        {currentProposalStatus == PROPOSAL_STATUS.WIN_THEME_REVIEW && (
          <WinThemeReview />
        )}
      </ProposalContentWraper>
    </FixedContentWrapper>
  );
};
export default Proposal;
