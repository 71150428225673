import React, { useState } from "react";
import AvatarAssisant from "@app/components/avatar/assistant";
import { StatsWrapper } from "@app/components/chat-content/styles";
import { MESSAGE_ROLE } from "@app/api/types";
import { useAppDispatchContext, useAppContext } from "@app/app/appContext";
import { setChatQuery, setCurrentChatMode } from "@app/app/actions";
import { ActionIconButton } from "@app/components/chat-content/styles";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { ButtonGroup } from "devextreme-react/button-group";
import { CHAT_MODE, chatModeOptions } from "@app/app/types";
// import { queryStats } from "@app/app/queries";
import {
  ChatContentWrapper,
  ChatMessageWrapper,
  ModeWRapper,
} from "@app/components/chat-content/styles";
import Avatar from "../avatar/user";
import { ContentLoaderWrapper } from "@app/styles";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { useLocation } from "react-router-dom";

const NewChatContent = () => {
  const chat = useAppContext();
  const location = useLocation();
  const isAllModes = location.pathname == "/allModes";
  const dispatch = useAppDispatchContext();
  const {
    isCurrentChatQueryProcessing,
    processingMessage,
    currentChatMode,
    auth: { user },
    currentChat,
  } = chat;
  // const { isLoading, data } = queryStats(currentChatMode);
  // const statsData = typeof data == "string" ? JSON.parse(data || "{}") : data;
  const askQ = (e: React.MouseEvent<HTMLElement>) => {
    const { query } = e.currentTarget.dataset;
    dispatch(setChatQuery({ query }));
  };

  const chatError = chat.currentChat?.error;

  // if (isLoading)
  //   return (
  //     <ContentLoaderWrapper id="large-indicator">
  //       <LoadIndicator />
  //     </ContentLoaderWrapper>
  //   );
  return (
    <ChatContentWrapper>
      <ChatMessageWrapper from={MESSAGE_ROLE.ASSISTANT}>
        {processingMessage && (
          <div className="d-flex">
            <div>
              <Avatar email={user?.username} />
            </div>
            <div className="flex-grow-1">{processingMessage}</div>
            <div></div>
          </div>
        )}
        {!isCurrentChatQueryProcessing && !chatError && (
          <div className="d-flex">
            <div>
              <AvatarAssisant />
            </div>
            <div className="flex-grow-1">
              <div>
              Hello, my name is KBRaiN&#x2122;! I am your virtual assistant. I'm only a bot (not a human), but I'll do my best to help! Please ask me a question by typing in the field below and clicking the send icon, or pressing enter.
              </div>
            </div>
            <div></div>
          </div>
        )}
      </ChatMessageWrapper>
      {isCurrentChatQueryProcessing && !chatError && (
        <ChatMessageWrapper from="assistant">
          <div className="d-flex">
            <div>
              <AvatarAssisant />
            </div>
            <div className="flex-grow-1">
              <ActionIconButton icon={faComment} className="mt-1 blink" />
            </div>
            <div>
              <span>Now</span>
            </div>
          </div>
        </ChatMessageWrapper>
      )}
      {chatError && !isCurrentChatQueryProcessing && (
        <ChatMessageWrapper from="assistant">
          <div className="d-flex">
            <div>
              <AvatarAssisant />
            </div>
            <div className="flex-grow-1 text-danger">{chatError}</div>
            <div>
              <span>Now</span>
            </div>
          </div>
        </ChatMessageWrapper>
      )}
    </ChatContentWrapper>
  );
};
export default NewChatContent;
