export const ACTION_TYPES = {
    SET_CURRENT_CHAT: 'set-current-chat',
    SET_CURRENT_CHAT_ID: 'set-current-chat-id',
    SET_CURRENT_CHAT_MODE: 'set-current-chat-mode',
    UPDATE_CHAT_LIST: 'update-chat-list',
    UPDATE_CHAT_MESSAGES: 'update-chat-messages',
    SET_CHAT_QUERY_PROCESSING: 'set-chat-query-processing',
    SET_CHAT_PROCESSING_QUERY: 'set-chat-processing-query',
    SET_CHAT_QUERY: 'set-chat-query',
    SET_CHAT: 'set-chat',
    SET_AUTH: 'set-auth',
    UPDATING_FEEDBACK: 'updating-feedback',
    TOGGLE_LEFT_PANEL: 'toggle-left-panel',
    SET_CURRENT_PROPOSAL: 'set-current-proposal',
    UPDATE_PROPOSAL: 'update-proposal',
    SET_SELECTED_DATASOURCES: 'set-selected-datasource',
    SET_SELECTED_DATASET_FILES: 'set-selected-dataset-file',
    SET_LOADING_DATASET_FILES: 'set-loading-dataset-files',
    SET_CURRENT_FOCUS_CHAT_CONFIG: 'set-current-focus-chat-config',
    SET_FOCUS_CHAT_CONFIGURATIONS: 'set-focus-chat-configurations',
    SET_LOADING_FOCUS_CHAT_CONFIGURATIONS_STATUS: 'set-loading-focus-chat-configurations-status',
    TOGGLE_FOCUSED_CHAT: 'toggle-focused-chat',
    SET_GENERATE_RESPONSE_TOGGLE: 'set-generate-response-toggle',
}