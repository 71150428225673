import { FixWidthContentWrapper, GrayPage } from "@app/styles";
import Card from "react-bootstrap/Card";
import { LightBlubIcon, ScopeIcon, BrainIcon } from "@app/styles/icons";
import { FocusCard, FocusCardContainer } from "@app/components/landing/styles";
import { ProposalList } from "@app/components/focus-chat/proposal-history";
import { FocusChatList } from "@app/components/focus-chat/list";
import { useAppDispatchContext, useAppContext } from "@app/app/appContext";
import {
  setProcessingQuery,
  setChatQuery,
  setCurrentChat,
  setCurrentChatId,
  updateChatList,
  toggleLeftPanel as toggleLeftPanelAction,
  setCurrentChatProcessing,
} from "@app/app/actions";
import { useNavigate } from "react-router-dom";

import {
  IntroWrapper,
  ReleaseButton,
  ReleaseInfoIcon,
  PanelWrapper,
  MainPanel,
  TitleBlock,
  Stack,
} from "@app/components/chat-introduction/styles";
const Home = () => {
  const dispatch = useAppDispatchContext();
  const navigate = useNavigate();
  const chat = useAppContext();

  const goNewChat = () => {
    if (chat.currentChat?.id) {
      dispatch(setCurrentChatId({ id: null }));
      dispatch(setChatQuery({ query: null }));
    } else {
      dispatch(setCurrentChat({ id: null, error: null }));
      dispatch(setProcessingQuery({ query: null }));
      dispatch(setCurrentChatProcessing(false));
    }
    navigate(
      `/kchat/chat${chat.isFocusedChat ? "?support-focus-chat=true" : ""}`
    );
  };

  const goRFP = () => {
    navigate("/proposal");
  };
  return (
    <GrayPage>
      <FixWidthContentWrapper paddingBottom="80px" className="pt-3">
        <Card>
          <div className="d-flex align-items-center">
            <h5 className="m-3">Welcome to KBRaiN RFP&#x2122;</h5>
          </div>
        </Card>
        <Card className="mt-3">
          {/* <FocusCard onClick={goNewChat}>
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                +
              </Card.Body>
            </FocusCard> */}
          <ProposalList />
        </Card>
      </FixWidthContentWrapper>
    </GrayPage>
  );
};
export default Home;
