import { AccountInfo } from "@azure/msal-browser";
import { ACTION_TYPES } from "./types";
import { CHAT_MODE, chatContentType, message, currentProposalType, selectedDataSourcesType, generatedResponseType} from "@app/app/types";
import { ProposalRequestType } from "@app/api/rfp/types"

export const setAuth = (payload: { authToken: string, user: AccountInfo }) => {
  return {
    type: ACTION_TYPES.SET_AUTH,
    payload,
  };
};
export const toggleLeftPanel = (payload: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_LEFT_PANEL,
    payload,
  };
};
export const setCurrentFocusChatConfig = (payload: string) => {
  return {
    type: ACTION_TYPES.SET_CURRENT_FOCUS_CHAT_CONFIG,
    payload,
  };
};
export const setFocusChatConfigurations = (payload: string[]) => {
  return {
    type: ACTION_TYPES.SET_FOCUS_CHAT_CONFIGURATIONS,
    payload,
  };
};
export const setLoadingChatConfigurationsStatus = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_LOADING_FOCUS_CHAT_CONFIGURATIONS_STATUS,
    payload
  }
}
export const setCurrentChatMode = (payload: { mode: typeof CHAT_MODE.KBR | typeof CHAT_MODE.CHAT_GPT | typeof CHAT_MODE.CHAT_GPT3 | typeof CHAT_MODE.FILE }) => {
  return {
    type: ACTION_TYPES.SET_CURRENT_CHAT_MODE,
    payload,
  };
};
export const setCurrentChatId = (payload: { id: string }) => {
  return {
    type: ACTION_TYPES.SET_CURRENT_CHAT_ID,
    payload,
  };
};

export const setCurrentChat = (payload: { id?: string, error?:any }) => {
  return {
    type: ACTION_TYPES.SET_CURRENT_CHAT,
    payload,
  };
};
export const setCurrentChatProcessing = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_CHAT_QUERY_PROCESSING,
    payload,
  };
};

export const setLoadingDatasetFiles = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_LOADING_DATASET_FILES,
    payload,
  };
};
export const updateChatList = (payload: { chats: chatContentType[] }) => {
  return {
    type: ACTION_TYPES.UPDATE_CHAT_LIST,
    payload,
  };
};

export const updateChatMessages = (payload: chatContentType) => {
  return {
    type: ACTION_TYPES.UPDATE_CHAT_MESSAGES,
    payload,
  };
};

export const setChatQuery = (payload: { query: string }) => {
  return {
    type: ACTION_TYPES.SET_CHAT_QUERY,
    payload,
  };
};

export const setProcessingQuery = (payload: { query: string }) => {
  return {
    type: ACTION_TYPES.SET_CHAT_PROCESSING_QUERY,
    payload,
  };
};
export const setChat = (payload: {
  timestamp_id: string;
  prompt?: string;
  messages?: message[];
  error?: any;
  processingMessage?: string;
  isProcessing?: boolean;
  query?: null;
}) => {
  return {
    type: ACTION_TYPES.SET_CHAT,
    payload,
  };
};

export const updatingFeedback =  (payload: {
  chatId: string,
  index: number,
  feedback: number,
  processing: boolean,
}) => {
  return {
    type: ACTION_TYPES.UPDATING_FEEDBACK,
    payload,
  };
};

export const setCurrentProposal = (payload: currentProposalType) => {
  return {
    type: ACTION_TYPES.SET_CURRENT_PROPOSAL,
    payload,
  };
};


export const updateProposal = (payload: ProposalRequestType) => {
  return {
    type: ACTION_TYPES.UPDATE_PROPOSAL,
    payload,
  };
};

export const setCurrentSelectedDataSources = (payload: selectedDataSourcesType) => {
  return {
    type: ACTION_TYPES.SET_SELECTED_DATASOURCES,
    payload
  }
}

export const setCurrentSelectedDatasetFiles = (payload: selectedDataSourcesType) => {
  return {
    type: ACTION_TYPES.SET_SELECTED_DATASET_FILES,
    payload
  }
}

export const toggleFocusedChat = (payload: boolean) => {
  return {
    type: ACTION_TYPES.TOGGLE_FOCUSED_CHAT,
    payload
  }
}

export const setGenerateResponseToggle = (payload: generatedResponseType) => {
  return {
    type: ACTION_TYPES.SET_GENERATE_RESPONSE_TOGGLE,
    payload
  }
}