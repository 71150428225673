import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import { queryProposal } from "@app/app/queries";
import { useChatWinTheme } from "@app/app/mutations";
import { ActionIconButton } from "@app/components/chat-content/styles";
import { useAppContext } from "@app/app/appContext";
import {
  faThumbsUp,
  faEdit,
  faCircleLeft,
  faCircleRight,
  faPaperPlane,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCircleExclamation,
  faPencil,
  faSpinner,
  faCommentDots
} from "@fortawesome/free-solid-svg-icons";
import Button from "devextreme-react/button";
import List from "devextreme-react/list";
import { Tooltip } from "devextreme-react/tooltip";
import styled from "styled-components";
import { scrollToBottom } from "@app/utils";
import { PlainButton } from "@app/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputWrapper, BottomArea } from "@app/components/chat-input/styles";
import { SummaryCard, Wrapper, MessageBoard, ChatQueryMessage } from "./styles";
import Avatar from "@app/components/avatar/user";
import AvatarAssisant from "@app/components/avatar/assistant";

const WinThemeReview = () => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const quillRef = React.createRef();
  const [value, setValue] = useState("");
  const state = useAppContext();
  const [editWinTheme, setEditWinTheme] = useState(false)
  const {
    currentProposal: { id: currentProposalId, status: currentProposalStatus, isSendingWinThemeMessage, winThemeMessages, winTheme},    
  } = state;
  const { isLoading, error, data, refetch } = queryProposal(currentProposalId);
  const chatWinTheme = useChatWinTheme(currentProposalId);
  const modules = {
    toolbar: false,
  };
  useEffect(() => {
    if (currentProposalId) {
      refetch();
    }
  }, []);

  useEffect(() => {
    scrollToBottom(messagesEndRef, { behavior: "smooth" });
  }, [isSendingWinThemeMessage]);

  const sendManualEdit = (modifiedText) =>{
    chatWinTheme.mutate({
      proposal_id: currentProposalId,
      prompt: modifiedText
    });
    setEditWinTheme(false)
  }

  const cardContent = () => {
    const content = (winThemeMessages || data.winThemeMessages) || [{"user": "KBRaiN", "content": `The generated Win Theme is: ${winTheme || data.winTheme}`}]

    return (
      <>
        {content.map((message, idx) => {
          return (
            <ChatQueryMessage key={idx} className="d-flex">
              <p className="query">
                {message.user === 'KBRaiN' ? (
                  <AvatarAssisant className="mr-3" />
                ) : (
                  <Avatar email={message.user} />
                )}
              </p>
              <div className="flex-grow-1">
                <p>{message.content}</p>
              </div>
            </ChatQueryMessage>
          );
        })}
        {(isSendingWinThemeMessage) && (
          <ChatQueryMessage className="d-flex">
            <AvatarAssisant className="mr-3" />
            <div className="flex-grow-1">
              <ActionIconButton icon={faCommentDots} className="mt-1 blink" />
            </div>
          </ChatQueryMessage>
        )}
      </>
    );
  };

  const renderWinTheme = (data:any) => {

    if (winTheme || (data && data.winTheme)) {
      return (
        <SummaryCard className="card">
          <div className="card-body">
            <p className="d-flex w-100 flex-grow-1 justify-content-between  flex-column">
              <ActionIconButton icon={faPencil} onClick={(e)=>setEditWinTheme(true)} />
              {!editWinTheme && <p className="card-text">{winTheme || data.winTheme}</p>}
              {editWinTheme &&
                <form onSubmit={(e)=>{e.preventDefault(); sendManualEdit(`EDIT: ${e.target[0].value}`)}}>
                  <textarea 
                    className='w-100 wintheme' 
                    defaultValue={winTheme || data.winTheme} 
                    onClick={(e)=> {
                      e.stopPropagation()
                    }}
                    style={{minHeight:'100px'}} 
                  />
                  <div className="text-right"><Button useSubmitBehavior={true} className="mt-2">Save</Button></div>
                </form>
              }          
              
            </p>
          </div>
        </SummaryCard>
      );
    } else {
      if (data?.status && data.status == "failed") {
        return (
          <p className="d-flex align-items-center">
            <span className="mr-3">{data.derivedFrom.pdfName}</span>
            <ActionIconButton icon={faCircleExclamation} />
          </p>
        );
      } else if (data?.status && data.status == "processing") {
        return (
          <p className="d-flex align-items-center">
            <span className="mr-3">{data.derivedFrom.pdfName}</span>
            <ActionIconButton icon={faSpinner} spin />
          </p>
        );
      } else {
        return (
          <p>
            {data?.derivedFrom?.pdfName || 'No capture documents processed. Please return to the "Documents" screen to upload a capture document to generate a Win Theme.'} - {data?.status || ''}
          </p>
        );
      }
    }
  };
  const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      e.stopPropagation();
      onSendMessage();
    }
  };
  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    onSendMessage();
  };

  const onSendMessage = () => {
    //@ts-ignore
    const prompt = quillRef.current
      //@ts-ignore
      .getEditor()
      .getText()
      .replace(/(\r\n|\n|\r)/gm, "");

    chatWinTheme.mutate({
      proposal_id: currentProposalId,
      prompt: prompt
    });
    setValue("");
  };
  const submitProposal = () => {};
  return (
    <Wrapper key={1} className="d-flex justify-content-center">
      <div className="content">
        <form>
          <h4>Win Theme Review</h4>
          <List
            dataSource={[data]}
            height="calc( 100% - 300px )"
            itemRender={renderWinTheme}
          />
        </form>
      </div>
        <div>
          <MessageBoard className="card">
            <div className="card-header">
              <h5>{`Collaborate on the Win Theme`}</h5>
            </div>
            <div className="card-body d-flex p-0">
              <div className="h-100 overflow-auto">
                <div className="h-100 overflow-auto mt-3 mb-3">
                  {data && cardContent()}
                  <div ref={messagesEndRef}> </div>
                </div>
              </div>
            </div>
            
            <div className="card-text border-top pt-3">
              <InputWrapper
                className="d-flex align-items-center pt-3"
                hide={true}
                minW={true}
              >
                <ReactQuill
                  //@ts-ignore
                  ref={quillRef}
                  modules={modules}
                  formats={[]}
                  value={value}
                  theme="snow"
                  onChange={setValue}
                  onKeyDown={onKeydown}
                  placeholder="Send a message..."
                  className="inputbox flex-grow-1"
                />
                <PlainButton onClick={onClick}>
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    className={`cursor-pointer`}
                  />
                </PlainButton>
              </InputWrapper>
            </div>            
          </MessageBoard>
        </div>
    </Wrapper>
  );
};

export default WinThemeReview;
