import React, { useEffect, useState } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import { useAppContext, useAppDispatchContext } from "@app/app/appContext";
import { useAcquireToken } from "@app/app/auth/useAcquireToken";
import { setCurrentFocusChatConfig as setCurrentFocusChatConfigAction, toggleFocusedChat as toggleFocusedChatAction } from "@app/app/actions";
import FocusChatHome from "@app/components/landing/focus-chat";
import FocusChat from "@app/components/focus-chat";
import Home from "@app/components/landing";
import Chat from "@app/components/chat";
import { LoadIndicator } from "devextreme-react";

const FEATURE_FLAGS = {
  SUPPORT_FOCUS_CHAT: 'support-focus-chat'
}

const AppRoutes = () => {
  const [requireToken] = useAcquireToken('chat')
  const dispatch = useAppDispatchContext();
  const { auth } = useAppContext();
  const [tokenAcquired, setTokenAcquired] = useState<Boolean>(false)
  const [searchParams, setSearchParams ] = useSearchParams()
  const supportFocusChat = Boolean(searchParams.get(FEATURE_FLAGS.SUPPORT_FOCUS_CHAT))
  useEffect(() => {
    dispatch(toggleFocusedChatAction(supportFocusChat))
    auth.authToken && setTokenAcquired(true)
  }, [auth]); // Dependency array

  useEffect(() => {
    const currentFocusChatConfig = window.localStorage.getItem('currentFocusChatConfig')
    if(currentFocusChatConfig){
      dispatch(setCurrentFocusChatConfigAction(currentFocusChatConfig))
    }
  }, [])
  return (
    <Routes>
      <Route path="/" element={tokenAcquired ? supportFocusChat ? <FocusChatHome />: <Home/> : <LoadIndicator />} />
      <Route path="/chat/:chatId?" element={tokenAcquired ? supportFocusChat ? <FocusChat />: <Chat/>  : <LoadIndicator />} />
    </Routes>
  );
};

export default AppRoutes;
