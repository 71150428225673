import styled from "styled-components";

export const OverflowEllipsisText = styled.p<{line?:number; textAlign?: string}>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${ p=> p.line || 5};
  overflow: hidden;
  text-align: ${ p=> p.textAlign || `left`};
`;

export const HistoryWrapper = styled.div`
  height: calc(100vh - 340px);
  overflow: auto;
`