import { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import {
  faThumbsDown,
  faThumbsUp,
  faCommentDots,
  faClipboard,
} from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "devextreme-react/tooltip";
import { useAppContext } from "@app/app/appContext";
import moment from "moment-timezone";
import {
  ChatContentWrapper,
  ChatMessageWrapper,
  ModeWRapper,
  ContentWrapper,
} from "@app/components/chat-content/styles";
import { ContentLoaderWrapper } from "@app/styles";
import { LoadIndicator } from "devextreme-react/load-indicator";

import { defaultFormat, scrollToBottom, userTimeZone } from "@app/utils";
import Avatar from "@app/components/avatar/user";
import AvatarAssisant from "@app/components/avatar/assistant";
import { useAppDispatchContext } from "@app/app/appContext";
import { updateChatMessages, setCurrentChatMode } from "@app/app/actions";
import { ActionIconButton, Message, Tags } from "./styles";
import { useParams } from "react-router-dom";
import { MESSAGE_ROLE } from "@app/api/types";
import { queryChatMessages, queryChatFeedback } from "@app/app/queries";
import { useTrackFeedback } from "@app/app/mutations";
import { CHAT_MODE, chatModeOptions } from "@app/app/types";
import { useLocation } from "react-router-dom";
import MeesageTags from "@app/components/chat-content/tags";

export const ChatContent = () => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chat = useAppContext();
  const dispatch = useAppDispatchContext();
  const {
    currentChat,
    chats,
    chatMessages,
    currentChatMode,
    auth: { user },
  } = chat;

  const userEmail = user?.username;
  const { id } = currentChat || {};
  const {
    isProcessing: currentChatisProcessing,
    processingMessage: currentChatisProcessingMessage,
    error: currentChatisProcessingError,
  } = chats.find((c) => c.timestamp_id.toString() == id?.toString()) || {};
  const { messages: currentChatMessages } =
    chatMessages.find((c) => c.timestamp_id.toString() == id?.toString()) || {};

  const { isLoading, error, data } = queryChatMessages(id);
  const { data: feedback, refetch: feedbackRefetch } = queryChatFeedback(id);
  const trackFeedback = useTrackFeedback();
  const FEEDBACK_ACTIVE_CLASS = "active";
  useEffect(() => {
    if (data && id) {
      const chatMode = data[0]?.mode
        ? data[0]?.mode
        : data[0]?.vanilla === true
        ? CHAT_MODE.CHAT_GPT
        : CHAT_MODE.KBR;
      dispatch(setCurrentChatMode({ mode: chatMode }));
      dispatch(updateChatMessages({ timestamp_id: id, messages: data }));
    }
  }, [data, id]);

  useEffect(() => {
    if (currentChatMessages && scrollToBottom) {
      scrollToBottom(messagesEndRef);
    }
  }, [currentChatMessages]);

  useEffect(() => {
    if (currentChatisProcessing && scrollToBottom) {
      scrollToBottom(messagesEndRef, { behavior: "smooth" });
    } else {
    }
  }, [currentChatisProcessing]);

  useEffect(() => {
    feedbackRefetch();
  }, [id]);

  if (isLoading)
    return (
      <ContentLoaderWrapper id="large-indicator">
        <LoadIndicator />
      </ContentLoaderWrapper>
    );

  const onClickTrackFeedback = (e: React.MouseEvent<HTMLElement>) => {
    const { index, feedback } = e.currentTarget.dataset;
    const result = trackFeedback.mutate({
      chatId: id.toString(),
      index,
      feedback,
    });
  };
  const getFeedbackClass = (idx, value) => {
    return !Array.isArray(feedback) &&
      feedback &&
      feedback[idx]?.feedback == value
      ? FEEDBACK_ACTIVE_CLASS
      : "";
  };
  const chatError = currentChatisProcessingError || chat.currentChat.error;
  const copyToClipboard = (e: React.MouseEvent<HTMLElement>) => {
    const { prompt } = e.currentTarget.dataset;
    // dispatch(setChatQuery({ query: prompt }));
    //@ts-ignore
    navigator.clipboard.writeText(prompt);
  };
  return (
    <ChatContentWrapper>
      <ContentWrapper>
        {currentChatMessages &&
          currentChatMessages
            .filter((item) => item.role !== MESSAGE_ROLE.SYSTEM)
            .map((item, itemIdx) => {
              return (
                <div key={itemIdx}>
                  <ChatMessageWrapper from={"user"}>
                    <div className="d-flex">
                      <div>
                        <Avatar email={userEmail} />
                      </div>
                      <Message className="flex-grow-1 d-flex">
                          <div dangerouslySetInnerHTML={{ __html: item.user }} />
                      </Message>
                      <div>
                        <span>
                          {item.timestamp &&
                            moment
                              .unix(parseInt(item.timestamp))
                              .tz(userTimeZone)
                              .format(defaultFormat)}
                        </span>
                      </div>
                    </div>
                  </ChatMessageWrapper>
                  <ChatMessageWrapper from={"assistant"}>
                    <div className="d-flex">
                      <div>
                        <AvatarAssisant />
                      </div>
                      <Message className="flex-grow-1">
                        <ReactMarkdown>{item.assistant}</ReactMarkdown>
                        {item.citations?.map((citation, index) => (
                          <div key={index}>
                            <Tooltip
                              target={`#user${itemIdx.toString()}${index.toString()}`}
                              showEvent="mouseenter"
                              hideEvent="mouseleave"
                              hideOnOutsideClick={false}
                            >
                              {item.citation_content &&
                              item.citation_content[index]
                                ? item.citation_content[index]
                                    .replace("]", "")
                                    .replace("[", "")
                                : citation.text
                                    .replace("]", "")
                                    .replace("[", "")}
                            </Tooltip>
                            <button
                              id={`user${itemIdx.toString()}${index.toString()}`}
                              type="button"
                              key={index}
                              className="d-block btn btn-secondary btn-sm mb-1 text-left"
                              data-toggle="tooltip"
                              title="tooltip"
                              onClick={(e) => {
                                item.citation_urls && item.citation_urls[index]
                                  ? window.open(item.citation_urls[index])
                                  : "";
                              }}
                            >
                              {`${citation.filename}, Page ${citation.page}, Paragraph ${citation.paragraph}`}
                            </button>
                          </div>
                        ))}
                      </Message>
                      <div>
                        <MeesageTags item={item} />
                        <div>
                          {item.timestamp &&
                            moment
                              .unix(parseInt(item.timestamp))
                              .tz(userTimeZone)
                              .format(defaultFormat)}
                        </div>
                        <div className="d-flex mt-2 user-actions">
                          <div
                            data-prompt={item.assistant}
                            onClick={copyToClipboard}
                          >
                            <ActionIconButton icon={faClipboard} />
                          </div>
                          <div
                            data-index={itemIdx}
                            data-feedback={1}
                            onClick={onClickTrackFeedback}
                            className={`feedbackBtn  align-items-center d-flex ${getFeedbackClass(
                              itemIdx,
                              1
                            )}`}
                          >
                            {(currentChatMessages[itemIdx]
                              .feedbackProcessing !== true ||
                              (currentChatMessages[itemIdx]
                                .feedbackProcessing == true &&
                                currentChatMessages[itemIdx].feedback ==
                                  -1)) && (
                              <ActionIconButton icon={faThumbsUp} />
                            )}
                            {currentChatMessages[itemIdx].feedbackProcessing ===
                              true &&
                              currentChatMessages[itemIdx].feedback == 1 && (
                                <ContentLoaderWrapper size={"20"}>
                                  <LoadIndicator />
                                </ContentLoaderWrapper>
                              )}
                          </div>
                          <div
                            data-index={itemIdx}
                            data-feedback={-1}
                            onClick={onClickTrackFeedback}
                            className={`feedbackBtn  align-items-center d-flex ${getFeedbackClass(
                              itemIdx,
                              -1
                            )}`}
                          >
                            {" "}
                            {(currentChatMessages[itemIdx]
                              .feedbackProcessing !== true ||
                              (currentChatMessages[itemIdx]
                                .feedbackProcessing == true &&
                                currentChatMessages[itemIdx].feedback ==
                                  1)) && (
                              <ActionIconButton icon={faThumbsDown} />
                            )}
                            {currentChatMessages[itemIdx].feedbackProcessing ===
                              true &&
                              currentChatMessages[itemIdx].feedback == -1 && (
                                <ContentLoaderWrapper size={"20"}>
                                  <LoadIndicator />
                                </ContentLoaderWrapper>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ChatMessageWrapper>
                </div>
              );
            })}
        {currentChatisProcessing && currentChatMessages && (
          <ChatMessageWrapper from="">
            <div className="d-flex">
              <div>
                <Avatar email={userEmail} />
              </div>
              <div className="flex-grow-1">
                <div dangerouslySetInnerHTML={{ __html: currentChatisProcessingMessage}} />
              </div>
              <div>
                <span>Now</span>
              </div>
            </div>
          </ChatMessageWrapper>
        )}
        {currentChatisProcessing && (
          <ChatMessageWrapper from="assistant">
            <div className="d-flex">
              <div>
                <AvatarAssisant />
              </div>
              <div className="flex-grow-1">
                <ActionIconButton icon={faCommentDots} className="mt-1 blink" />
              </div>
              <div>
                <span>Now</span>
              </div>
            </div>
          </ChatMessageWrapper>
        )}
        {chatError && (
          <ChatMessageWrapper from="assistant">
            <div className="d-flex">
              <div>
                <AvatarAssisant />
              </div>
              <div className="flex-grow-1 text-danger">{chatError}</div>
              <div>
                <span>Now</span>
              </div>
            </div>
          </ChatMessageWrapper>
        )}
        <div ref={messagesEndRef} />
      </ContentWrapper>
    </ChatContentWrapper>
  );
};
