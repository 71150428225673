import React, { useEffect, useState, useCallback } from "react";
import ChatImg from "@app/styles/assets/chat.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatchContext, useAppContext } from "@app/app/appContext";
import { useListFocusChat } from "@app/app/mutations"
import {
  setProcessingQuery,
  setChatQuery,
  setCurrentChat,
  setCurrentChatId,
  toggleLeftPanel as toggleLeftPanelAction,
  setCurrentChatProcessing,
  setCurrentFocusChatConfig,
  setCurrentSelectedDataSources
} from "@app/app/actions";
import { ChatIcon } from "@app/components/chat-panel/styles";
import {  useParams } from "react-router-dom";
import { useInitialSendMessage } from "@app/app/mutations";
import { FocusCard, FocusCardContainer } from "@app/components/landing/styles";
import Card from "react-bootstrap/Card";
import { Popup } from "devextreme-react/popup";
import { HistoryChatList } from "@app/components/focus-chat/history";
import { LoadIndicator } from "devextreme-react/load-indicator";

export const FocusChatList = () => {
  const { chatId } = useParams();
  const chat = useAppContext();
  const {
    feature: { proposal },
  } = useAppContext();
  const { hideLeftPanel, chatConfigurations, loadingChatConfigurations } = chat;
  const dispatch = useAppDispatchContext();
  const navigate = useNavigate();
  const initialSendMessage = useInitialSendMessage();
  const listFocusChat = useListFocusChat()
  const [showSourcesModal, setShowSourcesModal] = useState(false);
  useEffect(() => {
    dispatch(setCurrentChatId({ id: chatId }));
    listFocusChat.mutate({"search_term":null})
  }, []);

  // useEffect(() => {
  //   if (data) {
  //     dispatch(updateChatList(data));
  //   }
  // }, [data]);
  // const data = [{ type: "General" }, { type: "Account" }, { type: "Finance" }];
  const data = chatConfigurations.map(c=>({ type: c}));
  const loadChatHistory = (e: React.MouseEvent<HTMLLIElement>) => {
    // const { timestampId } = e.currentTarget.dataset;
    // dispatch(setCurrentChatId({ id: timestampId }));
    // dispatch(setChatQuery({ query: null }));
    // navigate(`/kchat/chat/${timestampId}?support-focus-chat=true`); // TODO We should set constants for the routes
  };
  const goNewChat = (e) => {
      console.log(e.currentTarget.dataset.id)
      dispatch(setCurrentFocusChatConfig(e.currentTarget.dataset.id))
      dispatch(setCurrentChat({ id: null, error: null }));
      dispatch(setProcessingQuery({ query: null }));
      dispatch(setCurrentChatProcessing(false));
      navigate(`/kchat/chat?support-focus-chat=true`);
  };
  const toggleLeftPanel = () => {
    // setHideLeftPanel(!hideLeftPanel);
    dispatch(toggleLeftPanelAction(!hideLeftPanel));
  };

  const toggleSourceModal = useCallback((e) => {
    console.log('setCurrentFocusChatConfig',e.currentTarget.dataset.id)
    dispatch(setCurrentFocusChatConfig(e.currentTarget.dataset.id))
    setShowSourcesModal(true);
  }, [setShowSourcesModal]);

  const hideSourceModal = useCallback(() => {
    setShowSourcesModal(false);
  }, [setShowSourcesModal]);

  return (
    <>
      {loadingChatConfigurations && <FocusCard
            className={`d-flex align-items-center`}>
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
            <LoadIndicator />
            </Card.Body>
          </FocusCard>}
      {!loadingChatConfigurations && Array.isArray(data) &&
        data?.map((i, index) => (
          <FocusCard
            key={index}
            className={`d-flex align-items-center`}
            data-id={i.type}
            onClick={goNewChat}
          >
            <Card.Body className="d-flex flex-column align-items-center justify-content-center p-3">
              <ChatIcon src={ChatImg} className="mr-2 mt-1" />
              <p className="mb-0">{i.type}</p>
            </Card.Body>
          </FocusCard>
        ))}
      <Popup
        width={"770px"}
        height={"50vh"}
        visible={showSourcesModal}
        onHiding={hideSourceModal}
        hideOnOutsideClick={true}
        showCloseButton={true}
        title={`Select a chat history`}
      >
        <div className="popup-content h-100">
          <div className="content p-3 h-100">
            <FocusCardContainer className="d-flex m-3 h-100 overflow-auto">
              <HistoryChatList />
            </FocusCardContainer>
          </div>
        </div>
      </Popup>
    </>
  );
};
