import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle<{ $whiteColor?: boolean; }>`
  body {
    font-family: 'Lato'
  }
  @keyframes blink {  
    0% { color: #f7f7f7; }
    100% { color: #333; }
  }
  @-webkit-keyframes blink {
    0% { color: #f7f7f7; }
    100% { color: #333; }
  }
  .blink {
    -webkit-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    animation: blink 1s linear infinite;
  } 
  .dx-popup {
    white-space: pre-wrap;     
    background-color: red;
  }

  .dx-popup-content {
    max-width: 800px;
    overflow: auto;
    white-space: pre-line !important;
    text-align: left;
    padding: 0;
  }
  
  .dx-list-item-content {
    color: black !important;
    white-space: normal;
  }
  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
    background-color: #f5f5f5 !important;
  }
  .badge {
    &.word-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
  .badge-container {
    background: red;
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .badge-container .badge {
    display:inline; /*Remove this to have a different rendring*/
  }
`