import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMsal, useAccount } from "@azure/msal-react";
import { scopes } from "@app/app/auth/authenticate";

type AvatarType = {
  email: string;
};
const genRanHex = (size) => Math.floor(size%10*16777215).toString(16)
const getRandomInt = (max) => Math.floor(Math.random() * max)
const colors = [
  '#ff7f50','#87cefa','#da70d6','#32cd32','#6495ed',
  '#ff69b4','#ba55d3','#cd5c5c','#ffa500','#40e0d0'
];
const UserInitialAvatar = styled.span<{ bgColor: string }>`
  width: 25px;
  height: 25px;
  & > img {
    width: 25px;
    height: 25px;
    border-radius: 25px;
  }
  border-radius: 25px;
  background-color: ${({ bgColor }) => bgColor};
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;


async function callMsGraph(accessToken: string) {
  const endpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";
  const options = {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  };

  try {
    const response = await fetch(endpoint, options);
    if (!response.ok) {
      throw new Error("Error fetching profile picture");
    }
    const data = await response.blob();
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(data);
    return imageUrl;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const Avatar = (props: AvatarType) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [avatar, setAvatar] = useState(null);  
  const { email = '' } = props;
  let charToNumber = 0
  const initial = email
    .split("@")[0]
    .split(".")
    .map((i) => {
      const n = i[0]?.toLocaleLowerCase().charCodeAt(0) - 97
      charToNumber += n
      return i[0];
    })
    .join("");
    getRandomInt(colors.length)
    colors[charToNumber%colors.length]

    useEffect(() => {
      if (account) {
          instance.acquireTokenSilent({
              scopes: ["https://graph.microsoft.com/profile"],
              account: account
          }).then((response) => {
              if(response) {
                  callMsGraph(response.accessToken).then((imageUrl) => setAvatar(imageUrl));
              }
          });
      }
  }, [account, instance]);  

  return (
    <UserInitialAvatar bgColor={colors[charToNumber%colors.length]}>
      {
        avatar ? 
          (
            <img src={avatar} alt={initial} />
          ) : 
          (
            initial
          )          
    }
    </UserInitialAvatar>
  );
};

export default Avatar;
