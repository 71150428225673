import React from "react";
import { Routes, Route } from "react-router-dom";
// import Proposal from "@app/components/proposal";
import Proposal from "@app/components/proposal/landing/proposal";
import ProposalEdit from "@app/components/proposal/edit";
import { useAcquireToken } from "./auth/useAcquireToken";


const AppRoutes = () => {
  const [requireToken] = useAcquireToken('proposal')
  return (
    <Routes>
      <Route path="/" element={<Proposal/>} />
      <Route path="/allModes" element={<Proposal />} />
      <Route path="/edit" element={<ProposalEdit/>} />
      <Route path="/:proposalId?/:status?" element={<ProposalEdit/>} />
    </Routes>
  );
};

export default AppRoutes;
