import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import ChatImg from "@app/styles/assets/chat.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faPlus,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { GearIcon, UserIcon, ArticleIcon } from "@app/styles/icons";
import { useAppDispatchContext, useAppContext } from "@app/app/appContext";
import {
  setProcessingQuery,
  setChatQuery,
  setCurrentChat,
  setCurrentChatId,
  updateChatList,
  toggleLeftPanel as toggleLeftPanelAction,
  setCurrentChatProcessing,
  setCurrentFocusChatConfig,
  setCurrentSelectedDataSources,
} from "@app/app/actions";
import {
  ChatIcon,
  OneLineText,
  ChatListWrapper,
  MoreOptions,
  LeftPanel,
} from "@app/components/chat-panel/styles";
import { OverflowEllipsisText } from "./styles";
import { Routes, Route, useParams } from "react-router-dom";
import { apiConfig } from "@app/api/index";
import * as CHAT_API from "@app/api/chats/endpoints";
import { useInitialSendMessage } from "@app/app/mutations";
import { FocusCard } from "@app/components/landing/styles";
import Card from "react-bootstrap/Card";
import moment from "moment-timezone";
import { defaultFormat, userTimeZone } from "@app/utils";
import { LoadIndicator } from "devextreme-react/load-indicator";

export const HistoryChatList = () => {
  const { chatId } = useParams();
  const chat = useAppContext();
  const {
    feature: { proposal },
  } = useAppContext();
  const { hideLeftPanel } = chat;
  const dispatch = useAppDispatchContext();
  const navigate = useNavigate();
  const initialSendMessage = useInitialSendMessage();
  const { isLoading, error, data } = useQuery({
    queryKey: ["chatList"],
    queryFn: () => apiConfig[CHAT_API.ENDPOINT_LIST_CHAT](),
  });

  useEffect(() => {
    dispatch(setCurrentChatId({ id: chatId }));
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(updateChatList(data));
    }
  }, [data]);

  const loadChatMessages = (e: React.MouseEvent<HTMLLIElement>) => {
    const { timestampId } = e.currentTarget.dataset;
    dispatch(setCurrentChatId({ id: timestampId }));
    dispatch(setChatQuery({ query: null }));
    dispatch(
      setCurrentSelectedDataSources({ focus: chat.currentFocusChatConfig })
    );
    navigate(`/kchat/chat/${timestampId}?support-focus-chat=true`); // TODO We should set constants for the routes
  };
  const goNewChat = () => {
    if (chat.currentChat?.id) {
      dispatch(setCurrentChatId({ id: null }));
      dispatch(setChatQuery({ query: null }));
    } else {
      dispatch(setCurrentChat({ id: null, error: null }));
      dispatch(setProcessingQuery({ query: null }));
      dispatch(setCurrentChatProcessing(false));
    }
    navigate("/kchat/chat");
  };
  const toggleLeftPanel = () => {
    // setHideLeftPanel(!hideLeftPanel);
    dispatch(toggleLeftPanelAction(!hideLeftPanel));
  };
  const goToProposal = () => {
    navigate(`/proposal`);
  };
  return (
    <>
      {isLoading && (
        <FocusCard className={`d-flex align-items-center`}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center">
            <LoadIndicator />
          </Card.Body>
        </FocusCard>
      )}
      {!error &&
        !isLoading &&
        Array.isArray(data) &&
        data?.map((i, index) => (
          <FocusCard
            key={index}
            data-timestamp-id={i.timestamp_id}
            className={`d-flex align-items-center ${
              chat.currentChat?.id == i.timestamp_id ? "active" : ""
            }`}
            onClick={loadChatMessages}
          >
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
              <ChatIcon src={ChatImg} className="mr-2 mt-1" />
              <OverflowEllipsisText>{i.prompt}</OverflowEllipsisText>
              <div className="timestamp">
                {i.timestamp_id &&
                  moment
                    .unix(parseInt(i.timestamp_id))
                    .tz(userTimeZone)
                    .format(defaultFormat)}
              </div>
            </Card.Body>
          </FocusCard>
        ))}
    </>
  );
};
