import { styled } from "styled-components";
export const COLOR = {
  WHITE: "#fff",
  LIGHT_GRAY: "#F5F5F5",
  LIGHTER_GRAY: "#888E93",
  CORN_FLOWER_BLUE: "#173753",
  CORN_FLOWER_BLUE_LIGHTER: "#2d4559",
  ICON_BG: "#004987",
  LIGHT_BLUE: "#4588EC",
  BORDER_GRAY: "#4a5259",
};

export const ContentWrapper = styled.div<{paddingBottom?: string, gap?: string}>`
  background-color: ${COLOR.LIGHT_GRAY};
  gap: ${({gap}) => gap+'px' || '30px'};
  .dx-datagrid .dx-row-alt {
    background-color: #ff0000;
  }
  padding-bottom: ${({paddingBottom}) => paddingBottom || '0'}
`;
export const ContentLoaderWrapper = styled.div<{ size?: string }>`
  width: ${({ size }) => size + "px" || "32px"};
  height: ${({ size }) => size + "px" || "32px"};
  margin: auto;
`;

export const PlainButton = styled.button`
  background: none;
  border: none;
  &:focus {
    outline: 0;
  }
`;


export const GrayPage = styled.div`
  background-color: ${COLOR.LIGHT_GRAY};
  height: 100%;
  overflow-y: auto;
`
export const FixWidthContentWrapper = styled.div<{paddingBottom?: string, gap?: string}>`
  background-color: ${COLOR.LIGHT_GRAY};
  margin: auto;
  width: 1200px;
  @media (max-width: 932px) {
    width: auto; 
    max-width: 100%; 
  }
  gap: ${({gap}) => gap+'px' || '30px'};
  .dx-datagrid .dx-row-alt {
    background-color: #ff0000;
  }
  padding-bottom: ${({paddingBottom}) => paddingBottom || '0'}
`;
