import { ACTION_TYPES } from "@app/app/actions/types";
import {
  CHAT_MODE,
  chatContentType,
  chatType,
  initialStateType,
} from "@app/app/types";
import { proposals } from "@app/components/proposal/fixtures/proposals";
export const initialState: initialStateType = {
  auth: {
    authToken: null,
    user: null,
  },
  isCurrentChatQueryProcessing: false,
  isLoadingDatasetFiles: false,
  currentFocusChatConfig: null,
  currentChatQuery: null,
  currentChat: null,
  currentChatMode: CHAT_MODE.KBR,
  chats: [],
  chatMessages: [],
  processingMessage: "",
  hideLeftPanel: false,
  currentProposal: {
    id: null,
    status: null,
    error: null,
    isProcessing: false,
    isSendingResponseMessage: false,
    isSendingRequirementMessage: false,
    isSendingExcluded: false,
    isSendingFeedback: false,
  },
  feature: {
    proposal: true,
  },
  currentSelectedSource: {
    skill: null,
    mode: null,
    datasets: [],
    files: [],
    focus: null,
  },
  chatConfigurations: [],
  generateResponseInProgress: {},
  loadingChatConfigurations: false,
  isFocusedChat: false
};
export const chatReducer = (state: initialStateType, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_AUTH:
      return {
        ...state,
        auth: action.payload,
      };

    case ACTION_TYPES.SET_SELECTED_DATASET_FILES:
      if (action.payload.files && action.payload.files[0]?.datasource) {
        const datasetId = action.payload.files[0].datasource;
        const { datasets } = state.currentSelectedSource;
        const dataset = datasets?.find(
          (d) => d.id == datasetId || d.name == datasetId
        ) || {};

        const newCurrentSelectedSourceUpdatedWithFiles = {
          ...state.currentSelectedSource,
          datasets: [
            ...(state.currentSelectedSource.datasets?.map((dataset) => {
              const match =
                dataset.id == datasetId || dataset.name == datasetId;
              return {
                ...dataset,
                files: match ? action.payload.files : dataset.files || [],
              };
            })||[]),
          ],
        };
        return {
          ...state,
          currentSelectedSource: newCurrentSelectedSourceUpdatedWithFiles,
        };
      }
      return state;

    case ACTION_TYPES.SET_SELECTED_DATASOURCES:
      const newCurrentSelectedSource = {
        ...state.currentSelectedSource,
        ...action.payload,
      };
      if (state.currentChat && parseInt(state.currentChat.id)) {
        const localStorageChatConfigs = JSON.parse(
          window.localStorage.getItem("chatConfigs") || "[]"
        );
        const existingOthers = localStorageChatConfigs.filter(
          (c) => parseInt(c.chatId) !== parseInt(state.currentChat.id)
        );
        const chatConfigs = [
          ...existingOthers,
          {
            chatId: parseInt(state.currentChat.id),
            currentSelectedSource: newCurrentSelectedSource,
          },
        ];
        window.localStorage.setItem("chatConfigs", JSON.stringify(chatConfigs));
      }
      return {
        ...state,
        currentSelectedSource: newCurrentSelectedSource || {},
      };

    case ACTION_TYPES.SET_CURRENT_FOCUS_CHAT_CONFIG:
      window.localStorage.setItem('currentFocusChatConfig', action.payload)
      return {
        ...state,
        currentSelectedSource: {
          ...state.currentSelectedSource,
          focus: action.payload,
        },
        currentFocusChatConfig: action.payload,
      };
    case ACTION_TYPES.SET_FOCUS_CHAT_CONFIGURATIONS:
      return {
        ...state,
        chatConfigurations: action.payload
      }
    case ACTION_TYPES.SET_LOADING_FOCUS_CHAT_CONFIGURATIONS_STATUS:
      return {
        ...state,
        loadingChatConfigurations: action.payload
      }
    case ACTION_TYPES.TOGGLE_LEFT_PANEL:
      return {
        ...state,
        hideLeftPanel: action.payload,
      };
    case ACTION_TYPES.SET_LOADING_DATASET_FILES:
      return {
        ...state,
        isLoadingDatasetFiles: action.payload,
      };
    case ACTION_TYPES.SET_CHAT_QUERY_PROCESSING:
      return {
        ...state,
        isCurrentChatQueryProcessing: action.payload,
      };
    case ACTION_TYPES.SET_CHAT_QUERY:
      return {
        ...state,
        currentChatQuery: action.payload.query,
      };
    case ACTION_TYPES.SET_CHAT_PROCESSING_QUERY:
      return {
        ...state,
        processingMessage: action.payload.query,
      };

    case ACTION_TYPES.SET_CURRENT_CHAT: {
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          ...action.payload,
        },
      };
    }
    case ACTION_TYPES.SET_CURRENT_CHAT_ID: {
      return {
        ...state,
        currentChat: {
          id: action.payload.id,
        }
      };
    }
    case ACTION_TYPES.SET_CURRENT_CHAT_MODE: {
      return {
        ...state,
        currentChatMode: action.payload.mode,
      };
    }
    case ACTION_TYPES.UPDATE_CHAT_LIST: {
      return {
        ...state,
        chats: action.payload,
      };
    }

    case ACTION_TYPES.UPDATE_CHAT_MESSAGES: {
      const oldChatMessages =
        state.chatMessages.find(
          (m) =>
            m.timestamp_id.toString() == action.payload.timestamp_id.toString()
        ) || {};
      return {
        ...state,
        chats: state.chats.map((chat) => {
          if (
            chat.timestamp_id.toString() !==
            action.payload.timestamp_id.toString()
          ) {
            return chat;
          }
          return {
            ...chat,
            ...action.payload,
          };
        }),
        chatMessages: [
          ...state.chatMessages.filter(
            (m) =>
              m.timestamp_id.toString() !==
              action.payload.timestamp_id.toString()
          ),
          { ...oldChatMessages, ...action.payload },
        ],
      };
    }
    case ACTION_TYPES.UPDATING_FEEDBACK: {
      return {
        ...state,
        chats: state.chats.map((chat: chatContentType) => {
          if (
            chat.timestamp_id.toString() !== action.payload.chatId.toString()
          ) {
            return chat;
          }
          return {
            ...chat,
            messages: chat.messages?.map((m, idx) => {
              if (idx === action.payload.index) {
                return {
                  ...m,
                  feedback: action.payload.feedback,
                  feedbackProcessing: action.payload.processing,
                };
              } else {
                return m;
              }
            }),
          };
        }),
        chatMessages: state.chatMessages.map((chat) => {
          if (
            chat.timestamp_id.toString() == action.payload.chatId.toString()
          ) {
            return {
              ...chat,
              messages: chat.messages.map((m, idx) => {
                if (idx === action.payload.index) {
                  return {
                    ...m,
                    feedback: action.payload.feedback,
                    feedbackProcessing: action.payload.processing,
                  };
                } else {
                  return m;
                }
              }),
            };
          } else {
            return chat;
          }
        }),
      };
    }
    case ACTION_TYPES.SET_CURRENT_PROPOSAL:
      return {
        ...state,
        currentProposal: {
          ...state.currentProposal,
          ...action.payload,
        },
      };
      case ACTION_TYPES.TOGGLE_FOCUSED_CHAT:
        return {
          ...state,
          isFocusedChat: action.payload
        };
      case ACTION_TYPES.SET_GENERATE_RESPONSE_TOGGLE:
        return {
          ...state,
          generateResponseInProgress: {
            ...state.generateResponseInProgress,
            ...action.payload
          }
        };
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};
