import { AccountInfo } from "@azure/msal-browser";
import { DataType } from "devextreme/common";
const showFile = true;
const showGPT3 = true;

export type chatType = {
  timestamp_id: string;
};
export type message = {
  timestamp: string;
  role: string;
  content: string;
  user: string;
  assistant: string;
  category?: string;
  mode: string;
  skill: string;
  system: string;
  feedback: number;
  feedbackProcessing: boolean;
  citations?: {
    text: string;
    filename: string;
    page: string;
    paragraph: string;
  }[];
  citation_content?: string[];
  citation_urls?: string[];
};
export type chatContentType = {
  timestamp_id: string;
  prompt?: string;
  messages?: message[];
  error?: any;
  processingMessage?: string;
  isProcessing?: boolean;
  query?: null;
};
export type chatMessagesType = {
  messages?: message[];
  timestamp_id: string;
};
export type currentProposalType = {
  id?: string;
  status?: string;
  error?: any;
  isProcessing?: boolean;
  isSendingResponseMessage?: boolean;
  isSendingRequirementMessage?: boolean;
  isSendingWinThemeMessage?: boolean;
  isSendingFeedback?: boolean;
  isSendingExcluded?: boolean;
  isFinalizeProcessing?: boolean;
  winTheme?: string;
  winThemeMessages?: [{user: string, content: string}];
  docxUrl?: string;
};
export type DatasetType = {
  id: string;
  created: number;
  name: string;
  type: string;
  files?: FileType[]
};
export type FileType = {
  id: string;
  filename: string;
  summary: string;
  category: string;
  datasource: string;
  url: string;
  tags: string[];
};

export type generatedResponseType = { 
  [key: string]: { 
    isGenerating: boolean, 
    response: { 
      summary: string, 
      citations: [] 
    }
  } 
};

export type initialStateType = {
  auth: {
    authToken?: string;
    user?: AccountInfo;
  };
  isCurrentChatQueryProcessing: boolean;
  isLoadingDatasetFiles: boolean;
  processingMessage: string;
  currentFocusChatConfig: null;
  currentChatQuery: string;
  currentChat: { id: string; error?: any };
  currentChatMode: typeof CHAT_MODE.CHAT_GPT | typeof CHAT_MODE.KBR;
  chats: chatContentType[];
  chatMessages: chatMessagesType[];
  hideLeftPanel: boolean;
  currentProposal: currentProposalType;
  feature: {
    proposal: boolean;
  };
  currentSelectedSource: {
    skill: string;
    mode: string;
    datasets: DatasetType[];
    files: FileType[];
    focus?: string;
  };
  loadingChatConfigurations: boolean;
  chatConfigurations: string[];
  isFocusedChat: boolean;
  generateResponseInProgress: generatedResponseType
};
export type selectedDataSourcesType = {
  skill?: string;
  mode?: string;
  datasets?: DataType[];
  files?: FileType[];
  focus?: string
};
export const CHAT_MODE = {
  KBR: "KBR",
  CHAT_GPT: "gpt-4",
  CHAT_GPT3: "gpt-3.5",
  FILE: "file",
};
export type chatModeTypes = typeof CHAT_MODE.KBR | typeof CHAT_MODE.CHAT_GPT;
export type chatModeOptionType = {
  mode: chatModeTypes;
  text: string;
  hint: string;
  disabled: boolean;
};
export const chatModeOptions: (
  isAllModes: boolean,
  chatMode?: chatModeTypes
) => chatModeOptionType[] = (isAllModes, chatMode = null) => {
  const chatGPT3 = {
    mode: CHAT_MODE.CHAT_GPT3,
    text: "GPT-3.5",
    hint: "gpt-3.5",
    disabled: chatMode && chatMode == CHAT_MODE.CHAT_GPT3 ? true : false,
  };
  const file = {
    mode: CHAT_MODE.FILE,
    text: "OneDrive",
    hint: "file",
    disabled: chatMode && chatMode == CHAT_MODE.FILE ? true : false,
  };
  const modes = [
    {
      mode: CHAT_MODE.KBR,
      text: "KBR",
      hint: "KBR",
      disabled: chatMode && chatMode == CHAT_MODE.CHAT_GPT ? true : false,
    },

    {
      mode: CHAT_MODE.CHAT_GPT,
      text: "GPT-4",
      hint: "gpt-4",
      disabled: chatMode && chatMode == CHAT_MODE.KBR ? true : false,
    },
  ];
  if (showGPT3) {
    modes.splice(1, 0, chatGPT3);
  }
  if (showFile) {
    modes.splice(1, 0, file);
  }
  return modes;
};

export const ERROR = {
  ACCESS_DENIED: "access_denied",
};
