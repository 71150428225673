import { useEffect, useState } from "react";
import { TextBox } from "devextreme-react/text-box";
import ValidationSummary from "devextreme-react/validation-summary";
import { useLocation, useNavigate } from "react-router-dom";
import Validator, { RequiredRule } from "devextreme-react/validator";
import Button from "devextreme-react/button";
import { useAppContext } from "@app/app/appContext";
import { useCreateProposal, useUpdateProposal } from "@app/app/mutations";
import { queryProposal } from "@app/app/queries";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { EDIT_MODE } from "./types";

const HeaderInfo = () => {
  const state = useAppContext();
  const navigate = useNavigate();
  const {
    currentProposal: { id: currentProposalId, isProcessing },
  } = state;
  const { isLoading, data, refetch } = queryProposal(currentProposalId);
  const { id } = data || {};
  const [proposalName, setProposalName] = useState();
  const [opportunityNumber, setOpportunityNumber] = useState();
  const [proposalManager, setProposalManager] = useState();
  const [captureManager, setCaptureManager] = useState();
  const createProposal = useCreateProposal(currentProposalId);
  const updateProposal = useUpdateProposal(id);
  const { state: locaionState } = useLocation();
  const { mode } = locaionState || {};

  const onValueChanged = (e, func) => {
    func(e.value);
  };
  useEffect(() => {
    if (currentProposalId && mode == "edit") {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (data) {
      setProposalName(data.proposal_name);
      setOpportunityNumber(data.opportunity_number);
      setProposalManager(data.proposal_manager);
      setCaptureManager(data.capture_manager);
    }
  }, [data]);
  const onFormSubmit = (e) => {
    e.preventDefault();

    const payload = {
      proposal_name: e.target.proposal_name.value,
      client: "test",
      description: "test",
      proposal_manager: e.target.proposal_manager.value,
      capture_manager: e.target.capture_manager.value,
      opportunity_number: e.target.opportunity_number.value,
    };
    if (id !== currentProposalId) {
      createProposal.mutate({ ...payload, status: "header-info" });
    } else {
      updateProposal.mutate(payload);
    }
    navigate(`/proposal/${currentProposalId}/document`);
  };
  return (
    <div className="content">
      {(mode == EDIT_MODE.NEW) && (
        <form
          action="your-action"
          onSubmit={onFormSubmit}
          className="d-flex flex-column h-100 w-100"
        >
          <div className="d-flex flex-column flex-grow-1 h-100 w-100">
            <div className="row d-flex w-100">
              <div className="d-flex flex-column">
                <label>Proposal Name</label>
                <TextBox
                  name="proposal_name"
                  value={proposalName}
                  showClearButton={true}
                  valueChangeEvent="keyup"
                  onValueChanged={(e) => onValueChanged(e, setProposalName)}
                >
                  <Validator>
                    <RequiredRule message="Proposal Name is required." />
                  </Validator>
                </TextBox>
              </div>
              <div className="d-flex flex-column">
                <label>Opportunity Number(s)</label>
                <TextBox
                  name="opportunity_number"
                  value={opportunityNumber}
                  showClearButton={true}
                  valueChangeEvent="keyup"
                  onValueChanged={(e) =>
                    onValueChanged(e, setOpportunityNumber)
                  }
                >
                  <Validator>
                    <RequiredRule message="Opportunity Number is required." />
                  </Validator>
                </TextBox>
              </div>
              <div className="d-flex flex-column">
                <label>Capture Manager</label>
                <TextBox
                  value={captureManager}
                  name="capture_manager"
                  showClearButton={true}
                  valueChangeEvent="keyup"
                  onValueChanged={(e) => onValueChanged(e, setCaptureManager)}
                >
                  <Validator>
                    <RequiredRule message="Capture Manager is required." />
                  </Validator>
                </TextBox>
              </div>
            </div>
            <div className="row d-flex w-100">
              <div className="d-flex flex-column">
                <label>Proposal Manager</label>
                <TextBox
                  value={proposalManager}
                  name="proposal_manager"
                  showClearButton={true}
                  valueChangeEvent="keyup"
                  onValueChanged={(e) => onValueChanged(e, setProposalManager)}
                >
                  <Validator>
                    <RequiredRule message="Proposal Manager is required." />
                  </Validator>
                </TextBox>
              </div>
              <div></div>
            </div>
          </div>
          <ValidationSummary id="summary" className="mb-3" />
          <Button
            width="150px"
            id="button"
            text="Next"
            type="success"
            useSubmitBehavior={true}
          />
        </form>
      )}
    </div>
  );
};

export default HeaderInfo;
