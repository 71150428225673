
import * as CHAT_API from "@app/api/chats/endpoints"
import * as RFP_API from "@app/api/rfp/endpoints"

export const ENPOINTS_URL = {
  [CHAT_API.ENDPOINT_LIST_CHAT]: "/list_chat",
  [CHAT_API.ENDPOINT_VIEW_CHAT]: "/view_chat",
  [CHAT_API.ENDPOINT_INITIALIZE_CHAT]: "/initialize_chat",
  [CHAT_API.ENDPOINT_CONTINUE_CHAT]: "/continue_chat",
  [CHAT_API.ENDPOINT_UPLOAD_FILES]: "/upload_file",
  [CHAT_API.ENDPOINT_TRACK_FEEDBACK]: "/track_feedback",
  [CHAT_API.ENDPOINT_GET_FEEDBACK]: "/get_feedback",
  [CHAT_API.ENDPOINT_CHAT_STATS]: "/chat_stats",
  [CHAT_API.ENDPOINT_LIST_SKILLS]: "/list_skills",
  [CHAT_API.ENDPOINT_LIST_MODES]: "/list_modes",
  [CHAT_API.ENDPOINT_LIST_DATASETS]: "/list_datasets",
  [CHAT_API.ENDPOINT_LIST_FILES]: "/list_files",
  [CHAT_API.ENDPOINT_LIST_CHAT_CONFIGURATION]: "/list-focus-configurations",
  [CHAT_API.ENDPOINT_UPDATE_CHAT_CONFIGURATION]: "/update-focus-configuration",
  [CHAT_API.ENDPOINT_GET_CHAT_CONFIGURATION]: "/get-focus-configurations",
  [RFP_API.ENDPOINT_PROPOSAL_LIST]: "/proposal_list",
  [RFP_API.ENDPOINT_PROPOSAL_LIST_DATASETS]: "/list_datasets",
  [RFP_API.ENDPOINT_PROPOSAL_LIST_FILES]: "/list_files",
  [RFP_API.ENDPOINT_PROPOSAL]: "/proposal",
  [RFP_API.ENDPOINT_TEMPLATE]: "/template",
  [RFP_API.ENDPOINT_CHAT_PROPOSAL]: "/chat/proposal",
  [RFP_API.ENDPOINT_CHAT_WINTHEME_POST]: "/chat/wintheme",
  [RFP_API.ENDPOINT_CHAT_RESPONSE_POST]: "/chat/response",
  [RFP_API.ENDPOINT_CHAT_REQUIREMENT_POST]: "/chat/requirement",
  [RFP_API.ENDPOINT_RESPONSE_GET]: "/responses",
  [RFP_API.ENDPOINT_RESPONSE_POST]: "/generate-response",
  [RFP_API.ENDPOINT_PROPOSAL_STATUS_GET]: "/status",
  [RFP_API.ENDPOINT_FINALIZE_GET]: "/finalize",
  [RFP_API.ENDPOINT_REQUIREMENT_EXCLUDE]: "/exclude",
  [RFP_API.ENDPOINT_REQUIREMENT_FEEDBACK]: "/feedback",
};
