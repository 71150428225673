import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatchContext, useAppContext } from "@app/app/appContext";
import {
  setCurrentChatId,
  setCurrentSelectedDataSources,
  setChatQuery,
  setCurrentChat,
  setProcessingQuery,
  setCurrentChatProcessing,
  setCurrentFocusChatConfig,
  updateChatList,
  setFocusChatConfigurations,
} from "@app/app/actions";
import { ChatListWrapper, LeftPanel, SourceWRapper } from "./styles";
import { useParams } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import SelectBox, { SelectBoxTypes } from "devextreme-react/select-box";
import {
  DataGrid,
  Column,
  Selection,
  Scrolling,
  SearchPanel,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { ContentLoaderWrapper } from "@app/styles";
import { LoadIndicator } from "devextreme-react/load-indicator";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import {
  faArrowUpRightFromSquare,
  faPlus,
  faList,
  faL,
} from "@fortawesome/free-solid-svg-icons";
import { ActionIconButton } from "@app/components/chat-content/styles";
import {
  querySkillsList,
  queryModesList,
  queryDatasetsList,
  queryChatList,
} from "@app/app/queries";

import { useQuery } from "@tanstack/react-query";
import { useFileList } from "@app/app/mutations";
import { useListFocusChat } from "@app/app/mutations";
import { useSendMessage, useInitialSendMessage } from "@app/app/mutations";
import { apiConfig } from "@app/api/index";
import * as CHAT_API from "@app/api/chats/endpoints";
import { CHAT_MODE } from "@app/app/types";
export const SourcePanel = () => {
  const myRefs = useRef([]);
  const { chatId } = useParams();
  const navigate = useNavigate();
  const [currentFocusConfig, setCurrentFocusConfig] = useState(null);
  const [showChatHistoryList, setShowChatHistoryList] = useState(false);
  const [showFocusChatOptions, setShowFocusChatOptions] = useState(false);
  const [currentFocusChatId, setCurrentFocusChatId] = useState(chatId);
  const [showSourcesModal, setShowSourcesModal] = useState(false);
  const [showDatasetModal, setShowDatasetModal] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({});
  const chat = useAppContext();
  const {
    isProcessing: currentChatisProcessing,
    prompt: currentChatisProcessingMessage,
    error: currentChatisProcessingError,
  } = chat.chats.find(
    (c) => c.timestamp_id.toString() == currentFocusChatId?.toString()
  ) || {};
  const [currentMessage, setCurrentMessage] = useState("");
  const dispatch = useAppDispatchContext();
  const initialSendMessage = useInitialSendMessage();
  const sendMessage = useSendMessage(currentFocusChatId);
  const {
    hideLeftPanel,
    currentSelectedSource,
    isLoadingDatasetFiles,
    chatConfigurations,
  } = chat;
  const [initialSet, setInitialSet] = useState();
  const [currentSummary, setCurreySummary] = useState<{
    filename: string;
    summary: string;
    tags: string[];
  }>();

  const {
    isLoading: chatListLoading,
    error: chatListError,
    data: chatList,
    refetch: chatRefetch,
  } = queryChatList();
  const {
    isLoading: skillsLoading,
    error: skillsError,
    data: skillsList,
    refetch: skillsRefetch,
  } = querySkillsList();
  const {
    isLoading: modesLoading,
    error: modesError,
    data: modesList,
    refetch: modesRefetch,
  } = queryModesList();
  const {
    isLoading: datasetsLoading,
    error: datasetsError,
    data: datasetsList,
    refetch: datasetsRefetch,
  } = queryDatasetsList({
    focus: (
      chat.currentFocusChatConfig ||
      currentSelectedSource.focus ||
      ""
    )?.toLowerCase(),
  });
  // const {
  //   isLoading: filesLoading,
  //   error: filesError,
  //   data: filesList,
  //   refetch: filesRefetch,
  // } = queryFilesList();
  const fileList = useFileList();
  const listFocusChat = useListFocusChat();
  const { isLoading, error, data } = useQuery({
    queryKey: ["chatList"],
    queryFn: () => apiConfig[CHAT_API.ENDPOINT_LIST_CHAT](),
  });

  useEffect(() => {
    if (data) {
      dispatch(updateChatList(data));
    }
  }, [data]);
  useEffect(() => {
    chatRefetch();
    skillsRefetch();
    modesRefetch();
    listFocusChat.mutate({ search_term: null });
    // filesRefetch();
  }, []);
  useEffect(() => {
    dispatch(setCurrentChatId({ id: chatId }));
    setCurrentFocusChatId(chatId);
  }, []);
  // useEffect(()=>{
  //   if(chat.currentFocusChatConfig) {
  //     datasetsRefetch();
  //   }
  // },[chat.currentFocusChatConfig])

  useEffect(() => {
    if (currentSelectedSource) {
      datasetsRefetch();
    }
  }, [currentSelectedSource]);

  useEffect(() => {
    if (datasetsList) {
      if (datasetsList && Array.isArray(datasetsList)) {
        //@ts-ignore
        datasetsList.forEach((d) => {
          fileList.mutateAsync({
            dataset_id: d.id,
            max_items: 100,
          });
        });
      }
      dispatch(
        setCurrentSelectedDataSources({
          datasets: datasetsList,
        })
      );
    }
  },[datasetsList])
  useEffect(() => {
    if (chat.processingMessage) {
      setCurrentMessage(chat.processingMessage);
    }
  }, [chat.processingMessage]); 
  useEffect(()=>{
    console.log('ddd currentSelectedDataSources', chat.currentSelectedSource)
    const config = chat.currentChat?.id
      ? chat.currentSelectedSource.focus
      : chat.currentFocusChatConfig || "general";
    const configUseDefault = config ? config : "general";
    setCurrentFocusConfig(configUseDefault);
  }, [
    chat.currentChat?.id,
    chat.currentSelectedSource.focus,
    chat.currentFocusChatConfig,
  ]);
  const skillsData = skillsList?.map((s) => {
    return { id: s.label, title: s.label };
  });
  const modesData = modesList
    ?.map((m) => {
      return { id: m.value, title: m.name, value: m.value };
    })
    .sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  //@ts-ignore
  const sortDatasetList = datasetsList
    ? [
        ...datasetsList
          .filter((d) => d.type == "OneDrive")
          .map((d) => ({ ...d, name: "OneDrive" })),
        ...datasetsList.filter((d) => d.type !== "OneDrive"),
      ]
    : [];
  // const filesData = filesList?.files.map((f) => {
  //   return { id: f.label, title: f.label };
  // });

  // console.log("ddd currentSelectedSource", currentSelectedSource);
  // console.log("ddd skillsList", skillsList);
  // console.log("ddd modesList", modesData);
  // console.log("ddd datasetsList", datasetsList);
  console.log("ddd dataset", datasetsLoading, currentSelectedSource.datasets);
  // console.log("ddd selectedFiles", selectedFiles);
  // console.log("ddd current", currentSelectedSource.files);
  // console.log("ddd filesList", filesList);
  // const datasetsData = datasetsList?.map((d) => {
  //   return { id: d.id, title: d.name };
  // });
  //@ts-ignore
  const onChatConfigChanged = (e) => {
    if (e.value) {
      dispatch(setCurrentFocusChatConfig(e.value));
      setShowFocusChatOptions(false);
    }
  };
  const onChatRoomChanged = (e) => {
    const timestampId = e.value;
    setShowChatHistoryList(false);
    setCurrentFocusChatId(timestampId);
    dispatch(setCurrentChatId({ id: timestampId }));
    dispatch(setChatQuery({ query: null }));
    navigate(
      `/kchat/chat/${timestampId}${
        chat.isFocusedChat ? "?support-focus-chat=true" : ""
      }`
    ); // TODO We should set constants for the routes
  };
  const onSkillsChanged = useCallback((e: SelectBoxTypes.ValueChangedEvent) => {
    dispatch(
      setCurrentSelectedDataSources({
        skill: e.value,
      })
    );
  }, []);

  // const onSkillsChanged = useCallback((e) => {
  //   dispatch(setCurrentSelectedDataSources({
  //     skills: e.value
  //   }))
  // }, []);
  const onModesChanged = useCallback((e) => {
    skillsRefetch();
    dispatch(
      setCurrentSelectedDataSources({
        mode: e.value,
      })
    );
  }, []);
  // const onDatasetsChanged = useCallback((e) => {
  //   dispatch(
  //     setCurrentSelectedDataSources({
  //       datasets: e.value,
  //     })
  //   );
  // }, []);

  const dropDownOptions = {
    height: 300,
  };

  const openFile = (e) => {
    const url = e.currentTarget.dataset.url;
    window.open(url, "_blank");
  };

  const toggleSourceModal = useCallback(() => {
    setShowSourcesModal(true);
  }, [setShowSourcesModal]);

  const toggleDatasetModal = () => {
    setShowDatasetModal(true);
    setShowSourcesModal(false);
    datasetsRefetch();
  };

  const hideSourceModal = useCallback(() => {
    setShowSourcesModal(false);
  }, [setShowSourcesModal]);

  const hideDatasetModal = useCallback(() => {
    setShowDatasetModal(false);
  }, [setShowDatasetModal]);

  const addDataset = () => {
    if (selectedDataset && Array.isArray(selectedDataset)) {
      //@ts-ignore
      selectedDataset.forEach((d) => {
        fileList.mutateAsync({
          dataset_id: d.id,
          max_items: 100,
        });
      });
    }
    dispatch(
      setCurrentSelectedDataSources({
        datasets: selectedDataset,
      })
    );
    setShowDatasetModal(false);
  };
  const datasetModalButtonOptions = useMemo(
    () => ({
      width: 300,
      text: "Add",
      type: "default",
      stylingMode: "contained",
      onClick: addDataset,
    }),
    [addDataset]
  );

  const onDatasetSelectionChanged = (e) => {
    setSelectedDataset(e.component.getSelectedRowsData());
  };

  const onDatasetFilesSelectionChanged = (e) => {
    setSelectedFiles((prevValue) => {
      const newValue = {
        ...prevValue,
        [e.element.id]: e.component.getSelectedRowsData(),
      };
      if (Object.keys(newValue).length > 0) {
        let allFiles = [];
        Object.keys(newValue).forEach((d) => {
          allFiles = allFiles.concat(newValue[d]);
        });
        dispatch(
          setCurrentSelectedDataSources({
            files: allFiles,
          })
        );
      }
      return newValue;
    });
  };

  useEffect(() => {
    const all = {};
    if (currentSelectedSource.files && currentSelectedSource.files.length > 0) {
      currentSelectedSource.files.forEach((f) => {
        if (!all[f.datasource]) {
          all[f.datasource] = [];
        }
        all[f.datasource].push(f);
      });
      setSelectedFiles(all);
    }
  }, [currentSelectedSource]);

  const getNumberOfSelect = (datasource) => {
    const selectedfiles = currentSelectedSource.files
      ?.filter((f) => f.datasource == datasource.id)
      .map((f) => f.id);

    return selectedfiles?.length || 0;
  };

  const setValues = (e) => {
    const items = e.component.getDataSource()._items;
    e.component.off("selectionChanged");
    if (items && items.length > 0 && currentSelectedSource.files) {
      const selectedfiles = currentSelectedSource.files
        .filter((f) => f.datasource == items[0].datasource)
        .map((f) => f.id);
      const selectedIndexes = items
        .map((i, index) => {
          return { index, selected: selectedfiles.includes(i.id) };
        })
        .filter((i) => i.selected)
        .map((i) => i.index);
      e.component.selectRowsByIndexes(selectedIndexes, true);
    }
    e.component.on("selectionChanged", onDatasetFilesSelectionChanged);
  };
  const onFileDataGridCellClick = (e) => {
    if (e.rowType == "data" && e.column.dataField == "filename") {
      setCurreySummary(e.row.data);
      toggleSourceModal();
    }
  };

  const onFileDetailClick = (data) => {
    setCurreySummary(data);
    toggleSourceModal();
  };

  const newChat = () => {
    if (chat.currentChat?.id) {
      dispatch(setCurrentChatId({ id: null }));
      dispatch(setChatQuery({ query: null }));
    } else {
      dispatch(setCurrentChat({ id: null, error: null }));
      dispatch(setProcessingQuery({ query: null }));
      dispatch(setCurrentChatProcessing(false));
      dispatch(
        setCurrentSelectedDataSources({ focus: chat.currentFocusChatConfig })
      );
    }
    navigate(
      `/kchat/chat${chat.isFocusedChat ? "?support-focus-chat=true" : ""}`
    );
  };
  const onSyncDrive = () => {
    const payload = {
      id: currentFocusChatId,
      prompt: "sync drive",
    };
    if (currentFocusChatId) {
      sendMessage.mutate(payload);
    } else {
      initialSendMessage.mutate(payload);
    }
  };
  const currentChat = chatList?.find(
    (c) => c.timestamp_id == currentFocusChatId
  );
  useEffect(() => {
    if (
      !currentFocusChatId &&
      !chat.currentFocusChatConfig &&
      !currentSelectedSource.focus
    ) {
      dispatch(setCurrentSelectedDataSources({ focus: "general" }));
    }
  }, []);

  const getTrimedMessage = (text = "") => {
    return text.length > 20 ? text.substring(0, 20).concat("...") : text;
  };
  const chatConfigList = chatConfigurations.map((c) => ({ type: c }));

  //@ts-ignore
  return (
    <LeftPanel className="border-bottom pt-3 ml-3 pr-3 border-right">
      <ChatListWrapper className="p-1 d-flex flex-column " hide={hideLeftPanel}>
        <div className="d-flex mb-3 align-items-center justify-content-start">
          <label className="m-0">Focus </label>
          {!showFocusChatOptions && (
            <Badge bg="warning" className="text-white ml-2 ">
              {currentFocusConfig}
            </Badge>
          )}
          {showFocusChatOptions && (
            <SelectBox
              items={chatConfigList}
              className={"ml-2"}
              valueExpr="type"
              displayExpr="type"
              onValueChanged={onChatConfigChanged}
              value={currentFocusConfig}
            />
          )}
          {!showFocusChatOptions && (
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setShowFocusChatOptions(true);
              }}
            >
              Switch
            </button>
          )}
          {showFocusChatOptions && (
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setShowFocusChatOptions(false);
              }}
            >
              cancel
            </button>
          )}
        </div>
        <div className="d-flex mb-3 align-items-center justify-content-between">
          <div className="d-flex border-0 align-items-center">
            <label className="m-0">Chat</label>

            {!showChatHistoryList && (
              <Badge bg="success" className="text-white ml-2 text-truncate">
                {chat.currentChat?.id && currentChat?.prompt
                  ? getTrimedMessage(currentChat?.prompt)
                  : chat.currentChat?.id && !currentChat?.prompt ? chatList[0].prompt
                  : 'new'}
              </Badge>
            )}
            {showChatHistoryList && (
              <SelectBox
                items={chatList}
                className={"ml-2"}
                valueExpr="timestamp_id"
                displayExpr="prompt"
                onValueChanged={onChatRoomChanged}
                value={currentFocusChatId}
              />
            )}
            {!showChatHistoryList && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setShowChatHistoryList(true);
                }}
              >
                Switch
              </button>
            )}
            {showChatHistoryList && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setShowChatHistoryList(false);
                }}
              >
                cancel
              </button>
            )}
          </div>
          {chat.currentChat?.id && (
            <div
              onClick={newChat}
              className="d-flex ml-1 rounded border p-1 align-items-center cursor-pointer"
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              <ActionIconButton
                icon={faPlus}
                className="blockquote-footer"
                style={{ fontSize: "14px" }}
              />
              <span className="ml-1">New Chat</span>
            </div>
          )}
        </div>
        <div className="mt-2 mb-2">
          <label>Modes</label>
          <SelectBox
            items={modesData}
            placeholder="Auto"
            valueExpr="id"
            value={currentSelectedSource.mode}
            displayExpr="title"
            onValueChanged={onModesChanged}
            showClearButton={true}
          />
        </div>
        {currentSelectedSource.mode == CHAT_MODE.KBR && (
          <div>
            <label>Skills</label>
            <SelectBox
              items={skillsData}
              placeholder="Auto"
              valueExpr="id"
              displayExpr="title"
              value={currentSelectedSource.skill}
              onValueChanged={onSkillsChanged}
              showClearButton={true}
            />
          </div>
        )}
        <div className="d-flex align-items-center mt-3 mb-3 justify-content-between">
          <div className="d-flex align-items-center">
            <label className="m-0">Sources</label>
            <button
              type="button"
              className="btn btn-link"
              onClick={onSyncDrive}
            >
              Sync drive
            </button>
          </div>
          {false && (
            <div
              onClick={toggleDatasetModal}
              className="ml-1 rounded border p-1"
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              <ActionIconButton icon={faPlus} style={{ fontSize: "14px" }} />
              <span className="ml-1">Add Source</span>
            </div>
          )}
        </div>
        <SourceWRapper>
          {datasetsLoading && (
            <ContentLoaderWrapper className="d-flex justify-content-center align-items-center h-100">
              <LoadIndicator />
              <p className="m-0 ml-2">loading datasets...</p>
            </ContentLoaderWrapper>
          )}
          {!datasetsLoading && (
            <TabPanel
              width="99.8%"
              animationEnabled={true}
              showNavButtons={true}
              focusStateEnabled={false}
              height={"100%"}
              className="pb-3"
            >
              {currentSelectedSource.datasets?.map((dataset, index) => {
                return (
                  <TabPanelItem
                    key={index}
                    title={
                      dataset.name.includes("drive-")
                        ? "OneDrive"
                        : dataset.name
                    }
                  >
                    {isLoadingDatasetFiles && (
                      <ContentLoaderWrapper className="d-flex justify-content-center align-items-center h-100">
                        <LoadIndicator />
                      </ContentLoaderWrapper>
                    )}
                    {!isLoadingDatasetFiles && dataset.files && (
                      <DataGrid
                        ref={(el) => (myRefs.current[index] = el)}
                        id={dataset.id}
                        dataSource={dataset.files}
                        showColumnHeaders={false}
                        keyExpr="id"
                        showBorders={false}
                        showColumnLines={false}
                        allowColumnReordering={true}
                        columnAutoWidth={false}
                        allowColumnResizing={true}
                        // onRowPrepared={dataEntryRow}
                        width={"100%"}
                        height={"100%"}
                        // onSelectionChanged={onDatasetFilesSelectionChanged}
                        noDataText="No Files"
                        onContentReady={setValues}
                        onCellClick={onFileDataGridCellClick}
                        className="pr-2 pl-2 pt-2 pb-2"
                      >
                        <Toolbar>
                          <Item location="before">
                            <div className="informer d-flex align-items-center mb-2">
                              <div className="ml-1 mr-2">
                                <span>{getNumberOfSelect(dataset)} </span>
                                <span> selected. </span>
                              </div>
                            </div>
                          </Item>
                          <Item name="searchPanel" />
                        </Toolbar>
                        <Scrolling mode={"virtual"} />
                        <SearchPanel visible={true} width={100} />
                        <Selection
                          mode="multiple"
                          showCheckBoxesMode={"always"}
                        />
                        <Column dataField="filename" caption="File Name" />
                        <Column
                          dataField="url"
                          width="100px"
                          caption="File Name"
                          cellRender={(data) => {
                            return (
                              <div className="d-flex align-items-center">
                                <div
                                  data-url={data.data.url}
                                  onClick={openFile}
                                >
                                  <ActionIconButton
                                    icon={faArrowUpRightFromSquare}
                                    className="ml-3"
                                  />
                                </div>
                                <div
                                  onClick={() => onFileDetailClick(data.data)}
                                >
                                  <ActionIconButton
                                    icon={faList}
                                    className="ml-3"
                                  />
                                </div>
                              </div>
                            );
                          }}
                        />
                      </DataGrid>
                    )}
                  </TabPanelItem>
                );
              })}
            </TabPanel>
          )}
        </SourceWRapper>
      </ChatListWrapper>
      <Popup
        width={"50vw"}
        height={320}
        maxHeight={350}
        visible={showSourcesModal}
        onHiding={hideSourceModal}
        hideOnOutsideClick={true}
        showCloseButton={true}
        title={currentSummary?.filename}
      >
        <div className="popup-content">
          <div className="content p-3">
            {currentSummary?.summary}
            <br />
            {currentSummary?.tags?.map((t, index) => (
              <Badge
                key={index}
                bg="secondary"
                className="mt-2 mr-2 mb-2 text-white"
              >
                {t}
              </Badge>
            ))}
          </div>
        </div>
      </Popup>
      <Popup
        width={560}
        height={320}
        visible={showDatasetModal}
        onHiding={hideDatasetModal}
        hideOnOutsideClick={true}
        showCloseButton={true}
        title="Please select datasets"
      >
        <div className="popup-content h-100">
          <div className="content p-3 h-100">
            {datasetsLoading && (
              <ContentLoaderWrapper className="d-flex justify-content-center align-items-center h-100">
                <LoadIndicator />
                <p className="m-0 ml-2">loading datasets...</p>
              </ContentLoaderWrapper>
            )}
            {!datasetsLoading && (
              <DataGrid
                id="dataGrid"
                dataSource={sortDatasetList}
                showColumnHeaders={false}
                keyExpr="id"
                showBorders={false}
                showColumnLines={false}
                showRowLines={false}
                allowColumnReordering={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                // onRowPrepared={dataEntryRow}
                width={"100%"}
                onSelectionChanged={onDatasetSelectionChanged}
              >
                <Selection mode="multiple" showCheckBoxesMode={"always"} />
                <Column dataField="name" caption="Dataset Name" />
              </DataGrid>
            )}
          </div>
        </div>
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          disabled={selectedDataset ? selectedDataset.length == 0 : true}
          options={datasetModalButtonOptions}
        />
      </Popup>
    </LeftPanel>
  );
};
