import { useEffect } from "react";
import { DataGrid, Column } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Validator, { RequiredRule } from "devextreme-react/validator";
import { ProgressBar } from "devextreme-react/progress-bar";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import Button from "devextreme-react/button";
import FileUploader from "devextreme-react/file-uploader";
import Notify from "devextreme/ui/notify";
import { useAppContext } from "@app/app/appContext";
import { useUpdateProposal } from "@app/app/mutations";
import { queryProposal } from "@app/app/queries";
import { PROPOSAL_STATUS } from "./types";
import { convertBase64 } from "@app/utils";
import styled from "styled-components";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ActionIconButton } from "@app/components/chat-content/styles";
const Document = () => {
  const state = useAppContext();
  const {
    currentProposal: { id: currentProposalId, isProcessing },
  } = state;
  const updateProposal = useUpdateProposal(currentProposalId);
  const { isLoading, data, refetch } = queryProposal(currentProposalId);
  useEffect(() => {
    if (currentProposalId) {
      refetch();
    }
  }, [isProcessing]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const uploadFiles = Object.entries(e.target.uploadFile.files);
    const files = {};
    await Promise.all(
      uploadFiles.map(async (file) => {
        const base64All = await convertBase64(file[1]);
        //@ts-ignore
        const base64 = base64All.split(",")[1];
        //@ts-ignore
        const filename = e.target.fileName.value || file[1]?.name;
        files[filename] = {
          isWinTheme: e.target.isWinTheme.value === "true",
          data: base64,
        };
      })
    );
    if (Object.entries(files).length === 0) {
      Notify({
        message: "please select file",
        type: "error",
        displayTime: 3000,
      });
    } else {
      //put files
      updateProposal.mutate({
        files,
        status: PROPOSAL_STATUS.REQUIREMENT_REVIEW,
        isExcluded: true,
      });
    }
  };

  const dataEntryRow = (e) => {
    if (e.rowType == "data" && e.rowIndex % 2 === 0) {
      e.rowElement.style.backgroundColor = "#f5f5f5";
    }
  };

  const getFiles = () => {
    if (data?.files) {
      return Object.entries(data?.files).map((item) => ({
        name: item[0],
        //@ts-ignore
        ...item[1],
      }));
    } else {
      return [];
    }
  };
  const openFile = (e) => {
    const url = e.currentTarget.dataset.url;
    window.open(url, "_blank");
  };
  const deleteFile = (e) => {
    const { name } = e.data;
    updateProposal.mutate({
      files: { [name]: { ...e.data, delete: true } },
      isExcluded: true,
    });
  };

  const uncheckedLabel = { "aria-label": "Unchecked" };
  const Wrapper = styled.div`
    .dx-fileuploader-content {
      display: flex;
      > div {
        padding-top: 20px !important;
      }
    }
    .upload-button {
      margin-top: 30px;
    }
  `;
  return (
    <Wrapper className="content">
        <form
          action="your-action"
          onSubmit={onFormSubmit}
          className="d-flex flex-column w-100 mb-3"
        >
          <h3>Upload Document</h3>
          <div className="d-flex align-items-start mb-3">
            <div className="d-flex align-items-end">
              <div className="d-flex flex-column">
                <label>File Name</label>
                <TextBox
                  name="fileName"
                  showClearButton={true}
                  valueChangeEvent="keyup"
                ></TextBox>
              </div>
            </div>
            <div className="fileuploader-container w-25">
              <FileUploader
                selectButtonText="Select file"
                labelText=""
                name="uploadFile"
                uploadMode="useForm"
                multiple={true}
                onFilesUploaded={(e) => {
                  console.log(e);
                }}
              >
                <Validator>
                  <RequiredRule message="Files are required." />
                </Validator>
              </FileUploader>
            </div>
            <Button
              className="upload-button"
              text="Update profile"
              type="success"
              useSubmitBehavior={true}
            />
          </div>
          <div className="d-flex">
            <label>is capture doc?</label>
            <CheckBox
              name={"isWinTheme"}
              className="mb-3"
              elementAttr={uncheckedLabel}
            />
          </div>
        </form>
        <DataGrid
          id="dataGrid"
          dataSource={getFiles()}
          keyExpr="name"
          showBorders={true}
          allowColumnReordering={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          onRowPrepared={dataEntryRow}
          width={"100%"}
          className="mt-3"
        >
          <Column
            dataField="name"
            caption="File Name"
            cellRender={(data) => {
              return (
                <div data-url={data.data.url} onClick={openFile}>
                  {data.data.name}
                </div>
              );
            }}
          />
          <Column dataField="url" caption="Link" width={220} />
          <Column dataField="status" caption="Status" width={200} />
          <Column
            dataField="isWinTheme"
            caption="Capture Doc?"
            width={100}
            cellRender={(data) =>
              data.data.isWinTheme && <ActionIconButton icon={faCheck} />
            }
          />
          <Column
            dataField="progress"
            caption="Progress"
            width={100}
            cellRender={(data) => (
              <ProgressBar
                id="progress-bar-status"
                width="90%"
                min={0}
                value={Math.floor(data.data.progress * 100)}
              />
            )}
          />
          <Column
            width={100}
            cellRender={(data) => {
              return (
                <Button
                  text="Delete"
                  type="danger"
                  onClick={() => {
                    deleteFile(data);
                  }}
                />
              );
            }}
          />
        </DataGrid>
    </Wrapper>
  );
};

export default Document;
