import { useQuery } from "@tanstack/react-query";
import { apiConfig } from "@app/api/index";

import * as CHAT_API from "@app/api/chats/endpoints";
import * as RFP_API from "@app/api/rfp/endpoints";
import { useAppContext } from "@app/app/appContext";

export const queryChatList = () => {
  return useQuery({
    queryKey: ["chatList"],
    queryFn: () => apiConfig[CHAT_API.ENDPOINT_LIST_CHAT](),
  });
};

export const queryChatMessages = (id: string) => {
  return useQuery({
    queryKey: ["chats", id],
    queryFn: () => {
      if (!id) {
        return Promise.resolve(null);
      }
      return apiConfig[CHAT_API.ENDPOINT_VIEW_CHAT](id);
    },
  });
};
export const queryChatFeedback = (id: string) => {
  const chatId = id? id.toString() : ''
  return useQuery({
    queryKey: ["feedback", chatId],
    queryFn: () => {
      if (!id) {
        return Promise.resolve(null);
      }
      return apiConfig[CHAT_API.ENDPOINT_GET_FEEDBACK](id);
    },
  });
};

export const queryStats = (mode: string) => {
  return useQuery({
    queryKey: ["stats"],
    queryFn: () => {
      if (mode) {
        return apiConfig[CHAT_API.ENDPOINT_CHAT_STATS](mode);
      }
    },
  });
};

//rfp
export const queryProposalList = () => {
  return useQuery({
    queryKey: ["proposalList"],
    queryFn: () => apiConfig[RFP_API.ENDPOINT_PROPOSAL_LIST](),
    enabled: false,
    staleTime: Infinity,
  });
};

export const queryResponse = (id: string) => {
  return useQuery({
    queryKey: ["proposalResponse", id],
    queryFn: () => apiConfig[RFP_API.ENDPOINT_RESPONSE_GET]({proposal_id: id}),
    enabled: false,
    staleTime: Infinity,
  });
};

export const queryProposal = (id: string) => {
  return useQuery({
    queryKey: ["proposals", id],
    queryFn: () => apiConfig[RFP_API.ENDPOINT_PROPOSAL_GET](id),
    enabled: false,
    staleTime: Infinity,
  });
};

export const queryProposalStatus = (id:string) => {
  return useQuery({
    queryKey: ["proposalStatus",id],
    queryFn: () => apiConfig[RFP_API.ENDPOINT_PROPOSAL_STATUS_GET]({ proposal_id: id}),
    enabled: false,
    staleTime: Infinity,
  });
};

export const queryTemplateList = () => {
  return useQuery({
    queryKey: ["templateList"],
    queryFn: () => apiConfig[RFP_API.ENDPOINT_TEMPLATE_LIST](),
  });
};

export const querySkillsList = () => {
  return useQuery({
    queryKey: ["skillsList"],
    queryFn: () => apiConfig[CHAT_API.ENDPOINT_LIST_SKILLS](),
    enabled: false,
    staleTime: Infinity,
  });
};

export const queryModesList = () => {
  return useQuery({
    queryKey: ["modesList"],
    queryFn: () => apiConfig[CHAT_API.ENDPOINT_LIST_MODES](),
    enabled: false,
    staleTime: Infinity,
  });
};

export const queryDatasetsList = (props) => {
  return useQuery({
    queryKey: ["datasetsList",focus],
    queryFn: () => apiConfig[CHAT_API.ENDPOINT_LIST_DATASETS](props),
    enabled: false,
    staleTime: Infinity,
  });
};

export const queryProposalDatasetsList = (props) => {
  return useQuery({
    queryKey: ["proposalDatasetsList",focus],
    queryFn: () => apiConfig[RFP_API.ENDPOINT_PROPOSAL_LIST_DATASETS](props),
    enabled: false,
    staleTime: Infinity,
  });
};
